import { useCallback } from 'react';
import { UserMgtSvcClientv3 } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useUsersApiCall({
  authProvider,
  stack,
}) {
  const apiCall = useCallback(async () => {
    const userClient = new UserMgtSvcClientv3(stack, authProvider);
    const response = await userClient.getCurrentActiveUser();

    return response;
  }, [authProvider, stack]);

  return useApiCall({ apiCall });
}
