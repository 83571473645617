import {
  useCallback, useMemo,
} from 'react';
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

const useDeviceCacheApiCall = ({
  authProvider,
  stack,
  store,
  init,
}) => {
  const devicesFromStore = useMemo(() => store.state.printersData?.printers,
    [store.state.printersData?.printers]);

  const fetch = useCallback(async () => {
    if (devicesFromStore) {
      return devicesFromStore;
    }

    const options = {
      params: {
        limit: 200,
      },
    };

    const deviceCacheClient = new DeviceCacheApiClient(stack, authProvider);
    const deviceListResponse = await deviceCacheClient.list(options);
    const deviceList = deviceListResponse.data;

    store.setState({
      printersData: {
        lastUpdated: Date.now(),
        printers: deviceList,
      },
    }, { deepSafeSetValue: true });

    return deviceList;
  }, [authProvider, stack, store, devicesFromStore]);

  return useApiCall({
    fetch,
    init,
  });
};

export default useDeviceCacheApiCall;
