import styled from 'styled-components';
import { Card } from '@veneer/core';
import SMBMainTheme from '../../styles/styles';

export const Container = styled.div`
  background: ${({ theme }) => theme.color.background.default};
`;

export const CardContainer = styled(Card)`
  && {
    border-width: 0;
  }
`;

export const WidgetContainer = styled.div`
  margin: 24px 0 0;
  padding: 0 0 26px;
  min-height: 319px;

  @media ${SMBMainTheme.mediaQueries.large}, ${SMBMainTheme.mediaQueries.xLarge} {
    && {
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(${props => props.numberOfColumns}, 1fr);
    }
  }
`;
