// Disabling react/no-danger to be able to use "dangerouslySetInnerHTML" to include
// links in the middle of localized texts.
/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';

const divHTMLRender = ({
  text,
  className,
  tagReplaceStart,
  tagReplaceEnd,
  dataTestid,
}) => {
  let textParsed = text;
  if (tagReplaceStart && tagReplaceEnd) {
    textParsed = text
      .replaceAll('{{tagReplaceStart}}', tagReplaceStart)
      .replaceAll('{{tagReplaceEnd}}', tagReplaceEnd);
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: textParsed }}
      data-testid={dataTestid}
    />
  );
};

divHTMLRender.defaultProps = {
  tagReplaceStart: null,
  tagReplaceEnd: null,
  dataTestid: null,
};

divHTMLRender.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  tagReplaceStart: PropTypes.string,
  tagReplaceEnd: PropTypes.string,
  dataTestid: PropTypes.string,
};

export default divHTMLRender;
