import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BlackHPPlus, HyperlinkText, useRootContext } from '@jarvis/react-portal-addons';
import { DetailedUsageDataPrinterNameLink, publishEvent } from '../../../utils/analytics';
import {
  Container, HPPlusIcon, Image, ImageArea, Name, SubName,
} from './styles';

const DeviceName = ({
  cloudId,
  hpPlus,
  image,
  modelName,
  modelNumber,
  name,
  analyticsOptions,
}) => {
  const { dashboardDetailUsageModalPrinterClick } = useFlags();
  const { shell } = useRootContext();
  const { navigation } = shell;

  const url = useMemo(() => `/printers/${cloudId}#printerUsage`, [cloudId]);

  const nameText = useMemo(() => {
    if (name) {
      return name;
    }

    return `${modelName} (${modelNumber})`;
  }, [name, modelName, modelNumber]);

  return (
    <Container>
      <ImageArea data-testid="device-image">
        <Image>{image}</Image>
        {hpPlus && (
          <HPPlusIcon data-testid="device-hp-plus-icon">
            <BlackHPPlus height="20px" width="20px" />
          </HPPlusIcon>
        )}
      </ImageArea>
      <Name>
        <p className="label" data-testid="device-name">
          {dashboardDetailUsageModalPrinterClick ? (
            <HyperlinkText
              onClick={ev => {
                ev.preventDefault();
                publishEvent(DetailedUsageDataPrinterNameLink, analyticsOptions);
                navigation.push(url);
              }}
              url={url}
            >
              {nameText}
            </HyperlinkText>
          ) : (
            nameText
          )}
        </p>
        {name && <SubName className="caption-small" data-testid="device-model">{modelName}</SubName>}
      </Name>
    </Container>
  );
};

DeviceName.propTypes = {
  cloudId: PropTypes.string.isRequired,
  hpPlus: PropTypes.bool,
  image: PropTypes.node.isRequired,
  modelName: PropTypes.string.isRequired,
  modelNumber: PropTypes.string.isRequired,
  name: PropTypes.string,
  analyticsOptions: PropTypes.shape({
    associatedDeviceUuid: PropTypes.string,
    associatedDeviceProductNumber: PropTypes.string,
  }).isRequired,
};

DeviceName.defaultProps = {
  name: null,
  hpPlus: false,
};

export default DeviceName;
