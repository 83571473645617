import { useCallback, useMemo } from 'react';
import { path } from 'ramda';
import { getOfferingOrEntitlement } from '@jarvis/react-portal-addons';
import { DASHBOARD_ENTITLEMENTS } from '../utils/printerStatus';
import useAccountProgramInfosCall from './useAccountProgramInfosApiCall';

const FILTER = {
  states: [
    'ENABLED',
  ],
};

const useHpPlusApiCalls = ({
  authProvider,
  stack,
  store,
  version,
}) => {
  const getDataFromStore = useCallback(
    () => path(['state', 'dashboard', 'programInfos'], store),
    [store],
  );

  const storedData = getDataFromStore() || {};

  const programInfos = useAccountProgramInfosCall({
    authProvider,
    initialData: storedData,
    init: false,
    stack,
    store,
    version,
  });

  const entitlements = useMemo(() => {
    if (!programInfos?.data) {
      return {
        forestFirstEnable: false,
        printAnywhereEnable: false,
        smartSecurityEnable: false,
      };
    }

    return {
      smartSecurityEnable: !!getOfferingOrEntitlement(
        programInfos.data,
        DASHBOARD_ENTITLEMENTS.smartSecurity,
        FILTER,
      ),
      printAnywhereEnable: !!getOfferingOrEntitlement(
        programInfos.data,
        DASHBOARD_ENTITLEMENTS.printAnywhere,
        FILTER,
      ),
      forestFirstEnable: !!getOfferingOrEntitlement(
        programInfos.data,
        DASHBOARD_ENTITLEMENTS.forestFirst,
        FILTER,
      ),
    };
  }, [programInfos.data]);

  return {
    error: programInfos.error,
    loading: programInfos.pending,
    data: entitlements,
  };
};

export default useHpPlusApiCalls;
