import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  border-bottom: 1px solid ${({ theme }) => theme.color.stroke.default};
`;

export const Text1 = styled.div`
  margin: 0 0 0 5px;
  display: flex;
  color: ${({ theme }) => theme.color.foreground.default};
`;

export const Text2 = styled.div`
  margin: 0 0 0 5px;
  display: flex;
  color: ${({ theme }) => theme.color.foreground.light};
`;

export const Space = styled.div`
  margin-left: 28px;
`;
