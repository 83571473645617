import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  ErrorWidget,
  GRANT_CHECK_TYPE,
  GRANT_STATES,
  LoaderWidget,
  MFELoader,
  getOfferingOrEntitlement,
  useCallSuccess,
  useGrants,
} from '@jarvis/react-portal-addons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import primitives from '@veneer/primitives';
import useTheme from '@veneer/theme/dist/use_theme';
import {
  Container,
  Grid,
  HP,
  HPPlus,
  Header,
  HpPlusContainer,
  HpSmartProContainer,
  NotificationContainer,
  PrintersWidgetContainer,
  StatusContainer,
  UsageContainer,
} from './styles';
import Config from '../../Config';
import Background from '../Background';
import Welcome from '../Welcome';
import useUsersApiCall from '../../hooks/useUsersApiCall';
import useAccountProgramInfosApiCall from '../../hooks/useAccountProgramInfosApiCall';
import useAccountApiCall from '../../hooks/useAccountApiCall';
import HPPlusWidget from '../HPPlusWidget';
import HPSmartProWidget from '../HPSmartProWidget';
import HPUsageDataWidget from '../HPUsageDataWidget';
import GenericThemeProvider from '../../shared/GenericThemeProvider';
import {
  HomeScreenDisplayed,
  PrintersItemClicked,
  getModuleDisplayedEvent,
  getModuleInitializedEvent,
  publishEvent,
} from '../../utils/analytics';
import preloadMFE from '../../utils/preload';

const MFE_REFS = {
  PRINTERS: '@jarvis/react-smb-printers',
  STATUS: '@jarvis/react-ecp-status-widget',
  NOTIFICATION: '@jarvis/react-smb-notifications',
};

const ENTITLEMENT_SMART_PRO = 'ws-hp.com/smart-pro';
const SMART_PRO_GRANT = [{ grant: ENTITLEMENT_SMART_PRO }];
const SMART_PRO_GRANT_STATES = [GRANT_STATES.ENABLED];

const Dashboard = ({ stack, shell, properties }) => {
  const {
    authProvider,
    grants,
    localization,
    navigation,
    orgSelector,
    store,
  } = shell;

  const {
    consolidatedPortal,
    dashboardUsageDataUnitFromOrg,
    grantsSupport,
  } = useFlags();

  const theme = useTheme();

  const currentDate = useMemo(() => new Date(Date.now()), []);

  useEffect(() => {
    Config.add('smartSecuritySolutionDataId', properties.smartSecuritySolutionDataId);
  }, [properties.smartSecuritySolutionDataId]);

  const userInfosCall = useUsersApiCall({
    authProvider,
    stack,
    store,
  });

  const { data: userInfos } = userInfosCall;

  const programInfos = useAccountProgramInfosApiCall({
    authProvider,
    stack,
    init: false,
    store,
    version: grantsSupport ? 'v3' : 'v2',
  });

  const accountCall = useAccountApiCall({
    init: dashboardUsageDataUnitFromOrg,
  });

  const { grantsCall } = useGrants({
    grantsInterface: grants,
    checkType: GRANT_CHECK_TYPE.ALL,
    states: SMART_PRO_GRANT_STATES,
    grants: SMART_PRO_GRANT,
    init: grantsSupport,
  });

  const isFetching = userInfosCall.pending
    || programInfos.pending
    || grantsCall.pending
    || (dashboardUsageDataUnitFromOrg && accountCall.pending);
  const hasError = userInfosCall.error || programInfos.error || (dashboardUsageDataUnitFromOrg && accountCall.error);
  const isHpPlus = programInfos.data?.planInfo?.programLevel === 'HP+';
  const urlCountry = localization.country;
  const country = dashboardUsageDataUnitFromOrg ? (accountCall.data?.regionId || urlCountry) : urlCountry;
  const showLoader = isFetching || (userInfosCall.success && !programInfos.error && !programInfos.data);

  const onUserInfosSuccess = useCallback(data => {
    programInfos.makeApiCall({
      accountId: orgSelector.getOrgTenantId(),
      userId: data?.resourceId,
    });
  }, [programInfos, orgSelector]);

  useCallSuccess({
    call: userInfosCall,
    onSuccess: onUserInfosSuccess,
  });

  const hasSmartProGrant = grantsCall.data;

  const showDashboardHpSmartProWidget = useMemo(
    () => {
      if (grantsSupport && !isFetching) {
        return hasSmartProGrant;
      }

      return !isFetching
        && !!getOfferingOrEntitlement(
          programInfos.data,
          ENTITLEMENT_SMART_PRO,
          {
            states: [
              'ENABLED',
            ],
          },
        );
    },
    [hasSmartProGrant, isFetching, grantsSupport, programInfos.data],
  );

  useEffect(() => publishEvent(HomeScreenDisplayed), []);

  useEffect(() => {
    // MFE preloading
    preloadMFE(MFE_REFS.PRINTERS);
    preloadMFE(MFE_REFS.STATUS);
    preloadMFE(MFE_REFS.NOTIFICATION);
  }, []);

  const refresh = useCallback(() => {
    userInfosCall.makeApiCall();

    if (dashboardUsageDataUnitFromOrg) {
      accountCall.makeApiCall();
    }
  }, [
    accountCall,
    dashboardUsageDataUnitFromOrg,
    userInfosCall,
  ]);

  const SmartNotificationWidget = useMemo(() => (
    <NotificationContainer>
      <MFELoader
        analytics={{
          enable: true,
          events: {
            moduleDisplayed: {
              activity: 'Home-v01',
            },
          },
        }}
        card
        component={MFE_REFS.NOTIFICATION}
        data-testid="smart-notification-widget-mfe"
        header={false}
        onLoad={() => {
          publishEvent(getModuleInitializedEvent('ReactSmbNotifications'));
        }}
        properties={properties}
        stack={stack}
        type="SMBSmartNotification"
      />
    </NotificationContainer>
  ), [stack, properties]);

  const PrinterWidget = useMemo(() => (
    <PrintersWidgetContainer>
      <MFELoader
        analyticsEvents={{
          moduleDisplayed: getModuleDisplayedEvent('/ReactSmbPrinters/', 'Printers'),
          itemClicked: PrintersItemClicked,
        }}
        card
        component={MFE_REFS.PRINTERS}
        onLoad={() => {
          publishEvent(getModuleInitializedEvent('ReactSmbPrinters'));
        }}
        properties={properties}
        stack={stack}
        type="SMBPrintersStatusWidget"
      />
    </PrintersWidgetContainer>
  ), [properties, stack]);

  const roleFilterStatusWidget = useMemo(() => [
    'User-BusinessTransactionalSMB',
    'Admin-BusinessTransactionalSMB',
    'Invitation',
    ...(consolidatedPortal ? [
      'Owner-Personal',
      'User-Personal',
      'Personal',
    ] : []),
  ], [consolidatedPortal]);

  const StatusWidget = useMemo(() => (
    <StatusContainer>
      <MFELoader
        analyticsEvents={{
          moduleDisplayed: getModuleDisplayedEvent('/ReactEcpStatusWidget/', 'Status'),
        }}
        card
        component={MFE_REFS.STATUS}
        data-testid="status-widget-mfe"
        header={false}
        onLoad={() => {
          publishEvent(getModuleInitializedEvent('ReactEcpStatusWidget'));
        }}
        roleFilter={roleFilterStatusWidget}
        stack={stack}
        stateFilter={['Active', 'Pending', 'Expired']}
        settings={{
          enableLocalization: true,
          showUsers: true,
          contentStyle: `
            height: 100%;
            max-height: 100%;
            margin-top: 0;
          `,
          bodyStyle: `
            padding-bottom: 8px;
          `,
          itemHeight: '49px',
          itemsMargin: '0',
          itemsPadding: '0',
          mainItemHeight: '49px',
          itemMargin: '0',
          itemPadding: '0 0 0 12px',
          titlePadding: '0 0 0 8px',
          chevronSize: '17px',
          circleRadius: '50%',
          circleSize: '12px',
          titleGap: '0',
          subTitleGap: '12px',
          onlineColor: theme.color.primary.base.default,
          offlineColor: theme.color.white,
          activeColor: theme.color.positive.base.default,
          pendingColor: theme.color.foreground.light,
          expiredColor: theme.color.negative.base.default,
          offlineBorderColor: theme.color.neutral.base.default,
          devicesRedirectPath: '/printers',
          usersRedirectPath: '/users',
          cardBorderRadius: `${primitives.layout.cornerRadius4}px`,
          singleLoader: true,
          listDevicesHeight: '154px',
          listUsersHeight: '196px',
        }}
        customTexts={{
          'status.header.devices.total': 'status.header.devices.totalPrinters',
          'status.header.devices.offline': 'status.header.devices.notConnected',
          'status.header.devices.online': 'status.header.devices.connected',
        }}
      />
    </StatusContainer>
  ), [roleFilterStatusWidget, stack, theme.color]);

  if (showLoader) {
    return <LoaderWidget fullscreen />;
  }

  if (hasError) {
    return (
      <ErrorWidget
        dataTestId="error-widget"
        onRetry={refresh}
      />
    );
  }

  return (
    <GenericThemeProvider>
      <Container>
        <Background isHpPlus={isHpPlus} />
        <Grid hasSmartPro={showDashboardHpSmartProWidget} isHpPlus={isHpPlus}>
          <Header>
            {(isHpPlus
              ? <HPPlus size={64} background="dark" />
              : (
                <HP
                  appearance="singlecolor"
                  background="dark"
                  size={48}
                />
              )
            )}
            <Welcome className="title-regular" name={userInfos?.data?.givenName} />
          </Header>

          {SmartNotificationWidget}
          {StatusWidget}

          {PrinterWidget}
          <UsageContainer>
            <HPUsageDataWidget
              data-testid="addons-hp-data-usage-widget"
              currentDate={currentDate}
              country={country}
            />
          </UsageContainer>

          {isHpPlus && (
            <HpPlusContainer>
              <HPPlusWidget
                data-testid="hp-plus-widget"
                shell={shell}
                stack={stack}
              />
            </HpPlusContainer>
          )}

          {showDashboardHpSmartProWidget && (
            <HpSmartProContainer isHpPlus={isHpPlus}>
              <HPSmartProWidget data-testid="hp-smart-pro-widget" navigation={navigation} />
            </HpSmartProContainer>
          )}
        </Grid>
      </Container>
    </GenericThemeProvider>
  );
};

Dashboard.defaultProps = {
  stack: 1,
  properties: {},
};

Dashboard.propTypes = {
  stack: PropTypes.number,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any),
};

export default Dashboard;
