import styled from 'styled-components';
import primitives from '@veneer/primitives';
import {
  Button,
  Card,
  SegmentedControl,
  IconInfo as VeneerIconInfo,
} from '@veneer/core';
import { NoDataYet, mixins } from '@jarvis/react-portal-addons';
import divHTMLRender from '../../shared/divHTMLRender';
import { tabletAndMobile } from '../../styles/mixins';

export const Container = styled.div`
  background: ${({ theme }) => theme.color.background.default};
  height: 100%;

  ${tabletAndMobile(`
    width: 100%;
  `)}
`;

export const CardContainer = styled(Card)`
  height: 100%;

  && {
    border-left: 0;
    border-right: 0;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  min-height: 100px;
  flex-direction: ${props => (props.flexDirectionRowReverse ? 'row-reverse' : 'row')};
  justify-content: space-between;
  padding: ${primitives.layout.size4}px ${primitives.layout.size1}px 0 ${primitives.layout.size2}px;

  ${mixins.xs(`
    flex-direction: column;
    gap: ${primitives.layout.size3}px;
    padding: ${primitives.layout.size2}px ${primitives.layout.size1}px 0 ${primitives.layout.size2}px;
    min-height: 120px;
  `)}
`;

export const TotalUsage = styled(divHTMLRender)`
  color: ${({ theme }) => theme.color.foreground.default};
`;

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.color.foreground.default};
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${primitives.layout.size4}px;
`;

export const Toggle = styled(SegmentedControl)`
  ${mixins.xs(`
    width: 100%;
  `)}

  > * {
    ${mixins.xs(`
      justify-content: normal;
    `)}
  }
`;

export const HelpTooltipText = styled.div`
  display: flex;
  justify-content: center;
  max-width: 250px;

  > * {
    display: contents;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: ${props => (props.heightAllContainer ? '380px' : '315px')};
  align-items: center;
`;

export const Info = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: ${primitives.layout.size2}px;
  padding-right: ${primitives.layout.size3}px;
`;

export const SubHeaderTitle = styled.div`
  p {
    color: ${({ theme }) => theme.color.foreground.light};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
`;

export const ViewDetailsButton = styled(Button)`
  ${mixins.xs(`
    width: fit-content;
  `)}
`;

export const ContainerTotalSubHeader = styled.div`
  ${mixins.xs(`
    width: fit-content;
  `)}
`;

export const IconInfo = styled(VeneerIconInfo)`
  flex-shrink: 0;
`;

export const HeaderContainer = styled.div`
  padding: ${({ theme }) => `${theme.orbitalFour} ${theme.orbitalSix}`};
`;

export const NoDataAvailable = styled(NoDataYet)`
  && {
    height: ${props => (props.heightNoDataAvailable)};
  }

  min-width: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: ${props => (props.heightAllContainer ? '380px' : '315px')};
  align-items: center;
`;
