import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@veneer/core';
import { HyperlinkText, useI18n } from '@jarvis/react-portal-addons';
import { HpSmartProClicked, publishEvent } from '../../utils/analytics';
import Images from '../../assets/images';
import {
  Content,
  Header,
  Image,
} from './styles';

const HPSmartProWidget = ({ navigation }) => {
  const { t } = useI18n();

  return (
    <Card
      appearance="dropShadow"
      content={(
        <div data-testid="hp-smart-pro-container">
          <Header className="subtitle-regular" data-testid="hp-smart-pro-header">
            {t('dashboard.hpPlus.hpSmartPro.header')}
          </Header>
          <Content data-testid="hp-smart-pro-content">
            <Image data-testid="hp-smart-pro-image">
              <img alt={t('dashboard.hpPlus.hpSmartPro.header')} src={Images.smartPro} />
            </Image>
            <div className="caption" data-testid="hp-smart-pro-content">
              {t('dashboard.hpPlus.hpSmartPro.description')}
              {' '}
              <HyperlinkText
                onClick={ev => {
                  ev.preventDefault();
                  publishEvent(HpSmartProClicked);
                  navigation.push('/solutions/hp-smart-pro');
                }}
                url="/solutions/hp-smart-pro"
              >
                {t('dashboard.hpPlus.hpSmartPro.action')}
                {' '}
                &raquo;
              </HyperlinkText>
            </div>
          </Content>
        </div>
      )}
    />
  );
};

HPSmartProWidget.propTypes = {
  navigation: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default HPSmartProWidget;
