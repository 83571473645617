import React, {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import highchartsInterface from '@veneer/highcharts-interface';
import { Chart, ChartProvider } from '@veneer/core';
import useTheme from '@veneer/theme/dist/use_theme';
import primitives from '@veneer/primitives';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useI18n } from '@jarvis/react-portal-addons';
import usePrintAnywhereApiCall from '../../../hooks/usePrintAnywhereApiCall';
import usePrintAnywhereApiCallV2 from '../../../hooks/usePrintAnywhereApiCallV2';
import CardContainer from '../../../shared/CardContainer';
import {
  ContainerChart,
  ContainerChartLegend,
} from '../../../shared/ContainerChart';
import {
  ContainerLegend,
  Legend,
  LegendColor,
  LegendSection,
  LegendText,
} from '../../../shared/Legend';

const PrintAnyWhere = props => {
  const {
    showLine,
    shell,
    stack,
  } = props;

  const {
    authProvider,
  } = shell;

  const theme = useTheme();

  const { dashboardPrintAnywhereV2 } = useFlags();

  const [printers, setPrinters] = useState({});
  const { t } = useI18n();

  const printAnywhereApiCallV1 = usePrintAnywhereApiCall({
    authProvider,
    stack,
    init: !dashboardPrintAnywhereV2,
  });

  const printAnywhereApiCallV2 = usePrintAnywhereApiCallV2({
    authProvider,
    stack,
    init: dashboardPrintAnywhereV2,
  });

  const printAnywhereApiCall = dashboardPrintAnywhereV2 ? printAnywhereApiCallV2 : printAnywhereApiCallV1;

  useEffect(() => {
    const deviceList = printAnywhereApiCall.data;
    if (deviceList) {
      const list = deviceList.content || [];
      if (dashboardPrintAnywhereV2) {
        setPrinters({
          printersEnable: list.filter(printer => printer.printerSolutions.filter(
            solution => solution.name === 'PA' && solution.enabled,
          ).length === 1).length,
          printersDisable: list.filter(printer => printer.printerSolutions.filter(
            solution => solution.name === 'PA' && !solution.enabled,
          ).length === 1).length,
          totalPrinters: list.filter(printer => printer.printerSolutions.filter(
            solution => solution.name === 'PA',
          ).length === 1).length,
          withPrinter: list.filter(printer => printer.printerSolutions.filter(
            solution => solution.name === 'PA',
          ).length === 1).length > 0,
        });
      } else {
        setPrinters({
          printersEnable: list.filter(printer => printer.printOnTheGoEnabled === true).length,
          printersDisable: list.filter(printer => printer.printOnTheGoEnabled === false).length,
          totalPrinters: list.length,
          withPrinter: list.length > 0,
        });
      }
    }
  }, [printAnywhereApiCall.data, dashboardPrintAnywhereV2]);

  const content = useMemo(() => (
    <ContainerChartLegend>
      <ContainerChart data-testid="hp-plus-print-anywhere-chart">
        <ChartProvider chartInterface={highchartsInterface}>
          <Chart
            data-testid="hp-plus-print-anywhere-chart"
            options={
            {
              chart: {
                height: '210px',
                type: 'donut',
              },
              title: {
                text: `<span style="font-size: 40px">${printers.totalPrinters}</span>`,
              },
              subtitle: {
                useHTML: true,
                text: `<div style="text-align: center; width: 99%">${t('dashboard.hpPlus.printAnywhere.totalPrinters')}</div>`,
                style: {
                  whiteSpace: 'unset',
                },
              },
              plotOptions: {
                pie: {
                  innerSize: '85%',
                  size: '100%',
                  colors: printers.withPrinter
                    ? [
                      primitives.color.cornFlowerBlue6,
                      theme.color.foreground.disabled,
                    ]
                    : [theme.color.background.disabled],
                },
              },
              tooltip: {
                enabled: printers.withPrinter,
              },
              series: [
                {
                  name: `${t('dashboard.printers.header')}`,
                  data: printers.withPrinter ? [
                    [`${t('dashboard.hpPlus.printAnywhere.status.enable')}`, printers.printersEnable],
                    [`${t('dashboard.hpPlus.printAnywhere.status.disabled')}`, printers.printersDisable],
                  ] : [
                    ['unknown', 1],
                  ],
                  states: {
                    hover: {
                      enabled: printers.withPrinter,
                    },
                  },
                },
              ],
            }
          }
          />
        </ChartProvider>
      </ContainerChart>
      <LegendSection>
        <ContainerLegend>
          <Legend data-testid="hp-plus-print-anywhere-chart-status-enable-legend">
            <LegendColor
              color={primitives.color.cornFlowerBlue6}
              data-testid="hp-plus-print-anywhere-chart-status-enable-legend-color"
            />
            <LegendText
              className="label-small"
              data-testid="hp-plus-print-anywhere-chart-status-enable-legend-text"
            >
              {t('dashboard.hpPlus.printAnywhere.status.enable')}
            </LegendText>
          </Legend>
        </ContainerLegend>
        <ContainerLegend>
          <Legend data-testid="hp-plus-print-anywhere-chart-status-disabled-legend">
            <LegendColor
              color={theme.color.foreground.disabled}
              data-testid="hp-plus-print-anywhere-chart-status-disabled-legend-color"
            />
            <LegendText className="label-small" data-testid="hp-plus-print-anywhere-chart-status-disabled-legend-text">
              {t('dashboard.hpPlus.printAnywhere.status.disabled')}
            </LegendText>
          </Legend>
        </ContainerLegend>
      </LegendSection>
    </ContainerChartLegend>
  ), [
    printers.printersDisable,
    printers.printersEnable,
    printers.totalPrinters,
    printers.withPrinter,
    t,
    theme,
  ]);

  return (
    <CardContainer
      content={content}
      data-testid="hp-plus-widget-print-anywhere-card-container"
      headerText={t('dashboard.hpPlus.printAnywhere.header')}
      retryLoad={() => printAnywhereApiCall.forceFetch()}
      showError={!!printAnywhereApiCall.error}
      showLine={showLine}
      showLoading={printAnywhereApiCall.loading}
    />
  );
};

PrintAnyWhere.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  showLine: PropTypes.bool,
  stack: PropTypes.number,
};

PrintAnyWhere.defaultProps = {
  showLine: false,
  stack: 1,
};

export default PrintAnyWhere;
