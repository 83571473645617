import { useCallback, useEffect, useState } from 'react';
import { buildChartData, usageChartUtils, useCallSuccess } from '@jarvis/react-portal-addons';
import useJobTelemetryApiCall from '../../hooks/useJobTelemetryApiCall';
import {
  parseDataFaxYear,
  parseDataPrintAreaYear,
  parseDataPrintYear,
  parseDataScanYear,
} from '../../utils/jobTelemetry';

const useCalls = ({
  authProvider,
  stack,
  params,
  isSquareFeet,
  setDataTelemetry,
  setHasRun,
  numMonths,
  init = true,
}) => {
  const {
    ViewType,
    buildPrintAreaChartData,
  } = buildChartData;

  const { SQCM_TO_SQFT, SQCM_TO_SQM } = usageChartUtils;

  const [startAndEndDate, setStartAndEndDate] = useState({ ...params });

  const jobTelemetryApiCall = useJobTelemetryApiCall({
    authProvider,
    init,
    stack,
    params,
  });

  const {
    print: printCall,
    printArea: printAreaCall,
    scan: scanCall,
    fax: faxCall,
  } = jobTelemetryApiCall.data;

  const { success: successPrint, error: errorPrint } = printCall;
  const { success: successPrintArea, error: errorPrintArea } = printAreaCall;
  const { success: successScan, error: errorScan } = scanCall;
  const { success: successFax, error: errorFax } = faxCall;

  useEffect(() => {
    if (!init) {
      setHasRun(true);
    }
  }, [init, setHasRun]);

  useEffect(() => {
    if ((successPrint || errorPrint) && (successPrintArea || errorPrintArea)
      && (successScan || errorScan) && (successFax || errorFax)) {
      setHasRun(true);
    }
  }, [
    errorFax,
    errorPrint,
    errorPrintArea,
    errorScan,
    setHasRun,
    successFax,
    successPrint,
    successPrintArea,
    successScan,
  ]);

  const onPrintCallSuccess = useCallback(counters => {
    const printedCounters = parseDataPrintYear(counters);

    setDataTelemetry(state => ({
      ...state,
      printedCounters,
    }));
  }, [setDataTelemetry]);

  useCallSuccess({
    call: printCall,
    onSuccess: onPrintCallSuccess,
  });

  const onPrintAreaSuccess = useCallback(counters => {
    const unit = isSquareFeet ? SQCM_TO_SQFT : SQCM_TO_SQM;
    const printedAreaCounters = parseDataPrintAreaYear({
      counters,
      buildPrintAreaChartData,
      numMonths,
      unit,
      viewType: ViewType.Yearly,
      ...startAndEndDate,
    });

    setDataTelemetry(state => ({
      ...state,
      printedAreaCounters,
    }));
  }, [
    SQCM_TO_SQFT,
    SQCM_TO_SQM,
    ViewType.Yearly,
    buildPrintAreaChartData,
    isSquareFeet,
    numMonths,
    setDataTelemetry,
    startAndEndDate,
  ]);

  useCallSuccess({
    call: printAreaCall,
    onSuccess: onPrintAreaSuccess,
  });

  const onScanCallSuccess = useCallback(counters => {
    const scanCounters = parseDataScanYear(counters);
    setDataTelemetry(state => ({
      ...state,
      scanCounters,
    }));
  }, [setDataTelemetry]);

  useCallSuccess({
    call: scanCall,
    onSuccess: onScanCallSuccess,
  });

  const onFaxCallSuccess = useCallback(counters => {
    const faxCounters = parseDataFaxYear(counters);
    setDataTelemetry(state => ({
      ...state,
      faxCounters,
    }));
  }, [setDataTelemetry]);

  useCallSuccess({
    call: faxCall,
    onSuccess: onFaxCallSuccess,
  });

  const makeApiCall = useCallback(args => {
    setStartAndEndDate({ ...args });
    setHasRun(false);
    jobTelemetryApiCall.makeApiCall(args);
  }, [jobTelemetryApiCall, setHasRun]);

  return ({
    ...jobTelemetryApiCall,
    makeApiCall,
  });
};

export default useCalls;
