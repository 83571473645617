import { useCallback } from 'react';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { useApiCall } from '@jarvis/react-portal-addons';

import getPrintAnywhereStacks from '../utils/getPrintAnywhereStacks';

const usePrintAnywhereApiCall = ({
  authProvider,
  stack,
  init,
}) => {
  const fetch = useCallback(async () => {
    const jarvisWebHttpNoProvider = new JarvisWebHttpClient({ defaultAuthProvider: authProvider });
    const urlAPI = `${getPrintAnywhereStacks()[stack]}printer`;
    const response = await jarvisWebHttpNoProvider.get({ url: urlAPI });
    const { data } = response;
    return data;
  }, [authProvider, stack]);

  const {
    data,
    error,
    forceFetch,
    isFetching: loading,
  } = useApiCall({ fetch, init });

  return {
    data,
    error,
    forceFetch,
    loading,
  };
};

export default usePrintAnywhereApiCall;
