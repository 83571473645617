import primitives from '@veneer/primitives';

const breakpoints = {
  xsMin: '375px', // xs
  xsMax: primitives.layout.breakpoint0, // 575px - max mobile
  smMin: primitives.layout.breakpoint1, // 576px
  smMax: primitives.layout.breakpoint2, // 767px - max tablet
  mdMin: primitives.layout.breakpoint3, // 768px - desktop
  mdMax: primitives.layout.breakpoint4, // 991px
  lgMin: primitives.layout.breakpoint5, // 992px
  lgMax: primitives.layout.breakpoint6, // 1199px
  xlMin: primitives.layout.breakpoint7, // 1200px
  xlMax: '1679px',
  xtMin: '1680px',
};

const SMBMainTheme = {
  breakpoints,
  mediaQueries: {
    xs: `(max-width: ${breakpoints.xsMin}px)`,
    mobile: `(max-width: ${breakpoints.xsMax}px)`,
    tablet: `(min-width: ${breakpoints.smMin}px) and (max-width: ${breakpoints.mdMax}px)`,
    desktop: `(min-width: calc(${breakpoints.mdMin}px+1px)) and (max-width: ${breakpoints.lgMax}px)`,
    large: `(min-width: ${breakpoints.lgMin}px) and (max-width: ${breakpoints.xlMax})`,
    xLarge: `(min-width: ${breakpoints.xtMin})`,
  },
};

export default SMBMainTheme;
