import { TelemetryClient } from '@jarvis/web-stratus-client';

export default async function getJobsPrintAreaCounters({
  stack,
  authProvider,
  startDate,
  endDate,
}) {
  const client = new TelemetryClient(stack, authProvider);
  const response = await client.getAreaUsageByPrintCategoryCountersByTenancy(undefined, undefined, startDate, endDate);

  return response;
}
