import styled from 'styled-components';
import SMBMainTheme from '../../styles/styles';

export const ContainerChart = styled.div`
  margin: 6px 0 0;
  width: 100%;
  min-width: 210px;
  height: 210px;
  @media ${SMBMainTheme.mediaQueries.xLarge}, (min-width: ${SMBMainTheme.breakpoints.smMin}px) and (max-width: ${SMBMainTheme.breakpoints.mdMax}px), ${SMBMainTheme.mediaQueries.large} {
    width: 210px;
  }
`;

export const ContainerPrintChart = styled(ContainerChart)`
  @media (min-width: ${SMBMainTheme.breakpoints.smMin}px) {
    width: 210px;
  }
`;

export const ContainerChartLegend = styled.div`
  display: block;
  @media ${SMBMainTheme.mediaQueries.xLarge}, (min-width: ${SMBMainTheme.breakpoints.smMin}px) and (max-width: ${SMBMainTheme.breakpoints.mdMax}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerPrintChartLegend = styled(ContainerChartLegend)`
  &&{
    @media (min-width: ${SMBMainTheme.breakpoints.smMin}px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
