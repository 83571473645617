import React from 'react';
import {
  IconCheckmarkCircle,
  IconGear,
  IconMinusCircle,
  IconWarning,
} from '@veneer/core';

export const StateSeverity = {
  NONE: 0,
  REPORT: 1,
  WARNING: 2,
  ERROR: 3,
};

export function severityToIcon(severity) {
  switch (severity) {
    case StateSeverity.NONE:
      return (
        <IconCheckmarkCircle
          size={24}
          filled
          color="green6"
        />
      );
    case StateSeverity.REPORT:
      return (
        <IconGear
          size={24}
          filled
          color="hpBlue6"
        />
      );
    case StateSeverity.WARNING:
      return (
        <IconWarning
          size={24}
          filled
          color="darkOrange6"
        />
      );
    case StateSeverity.ERROR:
      return (
        <IconMinusCircle
          size={24}
          filled
          color="red6"
        />
      );
    default:
      return null;
  }
}

export function severityToString(severity) {
  switch (severity) {
    case StateSeverity.NONE:
      return 'Ready to Print';
    case StateSeverity.REPORT:
      return 'Finish Printer Setup';
    case StateSeverity.WARNING:
      return 'Needs Attention';
    case StateSeverity.ERROR:
      return 'Unable to Print';
    default:
      return null;
  }
}

export const ConnectionState = {
  OFFLINE: 0,
  ONLINE: 1,
};

export function connectionStateToString(connectionState) {
  switch (connectionState) {
    case ConnectionState.OFFLINE:
      return 'Not Connected';
    case ConnectionState.ONLINE:
      return 'Connected';
    default:
      return null;
  }
}

export const DASHBOARD_ENTITLEMENTS = {
  smartSecurity: 'ws-hp.com/smartsecurity',
  printAnywhere: 'ws-hp.com/potg',
  forestFirst: 'ws-hp.com/positiveprinting',
};
