import { useCallback } from 'react';
import { RoamApiClient, useApiCall } from '@jarvis/react-portal-addons';

const usePrintAnywhereApiCallV2 = ({
  authProvider,
  stack,
  init,
}) => {
  const fetch = useCallback(async () => {
    const roamApiClient = new RoamApiClient(stack, authProvider);
    const response = await roamApiClient.getPrinters({ size: 200, page: 0 });
    const { data } = response;
    return data;
  }, [authProvider, stack]);

  const {
    data,
    error,
    forceFetch,
    isFetching: loading,
  } = useApiCall({ fetch, init });

  return {
    data,
    error,
    forceFetch,
    loading,
  };
};

export default usePrintAnywhereApiCallV2;
