import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IconWarning, SegmentedControlItem, Tooltip } from '@veneer/core';
import {
  Card,
  ErrorWidget,
  FaxChart,
  HeaderArea,
  LoaderWidget,
  PrintAreaChart,
  PrintPageChart,
  ScanChart,
  SmartTooltip,
  buildChartData,
  useI18n,
  useRootContext,
} from '@jarvis/react-portal-addons';
import {
  UsageDataFaxUsageButton,
  UsageDataPrintAreaUsageButton,
  UsageDataPrintUsageButton,
  UsageDataScanUsageButton,
  UsageDataViewUsageDetailsButton,
  publishEvent,
} from '../../utils/analytics';
import { parseDetailedUsageData } from '../../utils/jobTelemetry';
import GenericThemeProvider from '../../shared/GenericThemeProvider';
import {
  formatValueToLocale,
  getEndDate,
  getStartDate,
} from '../../utils/usageData';
import DetailedUsageDataModal from '../DetailedUsageModal';
import ToggleUsageData from './ToggleUsageData';
import SelectYear from '../SelectYear';
import {
  CardContainer,
  Container,
  ErrorContainer,
  HeaderContainer,
  HeaderTitle,
  HelpTooltipText,
  IconInfo,
  Info,
  LoaderContainer,
  NoDataAvailable,
  SubHeader,
  SubHeaderTitle,
  Toggle,
  ToggleContainer,
  TotalUsage,
  ViewDetailsButton,
} from './styles';
import useCalls from './useCalls';

const {
  getInitialFaxData,
  getInitialPrintAreaData,
  getInitialPrintData,
  getInitialScanData,
} = buildChartData;

const ChartSelectValue = {
  Print: 0,
  PrintArea: 1,
  Scan: 2,
  Fax: 3,
};

const SegmentsTypes = {
  Print: 'print-page',
  PrintArea: 'print-area',
  Scan: 'scan',
  Fax: 'fax',
};

const HPUsageDataWidget = ({
  currentDate,
  country,
  'data-testid': dataTestId,
}) => {
  const { t } = useI18n();

  const { shell, stack } = useRootContext();
  const { authProvider, localization, orgSelector } = shell;

  const numMonths = 12;
  const isSquareFeet = useMemo(() => country.toLowerCase() === 'us', [country]);
  const [valueSelect, setValueSelect] = useState(ChartSelectValue.Print);
  const [indexSelect, setIndexSelect] = useState(ChartSelectValue.Print);
  const [showDetailedUsageModal, setShowDetailedUsageModal] = useState(false);
  const [subtitleText, setSubtitleText] = useState(
    t('dashboard.cardAnalytics.subtitleText.printPageNew'),
  );
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [numMonthsAverage, setNumMonthsAverage] = useState(numMonths);
  const [createdOrgDate, setCreatedOrgDate] = useState(null);

  const currentYearIsEqualSelectedYear = useMemo(
    () => currentDate.getFullYear() === selectedYear,
    [currentDate, selectedYear],
  );

  const [printChartData, setPrintChartData] = useState(
    getInitialPrintData(numMonths),
  );
  const [areaChartData, setAreaChartData] = useState(
    getInitialPrintAreaData(numMonths),
  );
  const [scanChartData, setScanChartData] = useState(
    getInitialScanData(numMonths),
  );
  const [faxChartData, setFaxChartData] = useState(
    getInitialFaxData(numMonths),
  );
  const [unit, setUnit] = useState('pages');
  const { dashboardHideSegmentedControl, dashboardShowNoDataYet } = useFlags();

  const total = useMemo(() => {
    switch (valueSelect) {
      case ChartSelectValue.Print:
        return printChartData.total;
      case ChartSelectValue.PrintArea:
        return areaChartData.total;
      case ChartSelectValue.Scan:
        return scanChartData.total;
      case ChartSelectValue.Fax:
        return {
          sent: faxChartData.sent.total,
          received: faxChartData.received.total,
        };
      default:
        return 0;
    }
  }, [printChartData, areaChartData, scanChartData, faxChartData, valueSelect]);

  const initialParams = useMemo(
    () => ({
      startDate: getStartDate(currentDate.getFullYear()),
      endDate: getEndDate(currentDate.getFullYear(), currentDate),
    }),
    [currentDate],
  );

  const chartWithoutAverage = useMemo(() => numMonthsAverage === 1, [
    numMonthsAverage,
  ]);

  const {
    printCall,
    printAreaCall,
    scanCall,
    faxCall,
    tenancyRegistryCall,
    allCalls,
  } = useCalls({
    authProvider,
    orgSelector,
    params: initialParams,
    selectedYear,
    currentDate,
    createdOrgDate,
    isSquareFeet,
    numMonths,
    stack,
    numMonthsAverage,
    setAreaChartData,
    setPrintChartData,
    setScanChartData,
    setFaxChartData,
    setNumMonthsAverage,
    setCreatedOrgDate,
  });

  const yearCreateOrg = createdOrgDate?.getFullYear();
  const monthCreateOrg = createdOrgDate?.getMonth();
  const isCurrentYearEqualYearCreateOrg = yearCreateOrg === currentDate.getFullYear();

  const pointStartAverage = useMemo(() => {
    if (yearCreateOrg === selectedYear) {
      return monthCreateOrg;
    }
    return 0;
  }, [monthCreateOrg, selectedYear, yearCreateOrg]);

  const {
    allRequestsFailed,
    error: errorJobTelemetry,
    pending: telemetryIsPending,
  } = allCalls.jobTelemetryApiCall;

  const { error: errorPrint } = printCall;
  const { error: errorPrintArea } = printAreaCall;
  const { error: errorScan } = scanCall;
  const { error: errorFax } = faxCall;

  const currentChart = useMemo(() => {
    switch (valueSelect) {
      case ChartSelectValue.Print:
        return {
          call: printCall,
          data: printChartData,
        };
      case ChartSelectValue.PrintArea:
        return {
          call: printAreaCall,
          data: areaChartData,
        };
      case ChartSelectValue.Scan:
        return {
          call: scanCall,
          data: scanChartData,
        };
      case ChartSelectValue.Fax:
        return {
          call: faxCall,
          data: faxChartData,
        };
      default:
        return {};
    }
  }, [
    printCall,
    printChartData,
    printAreaCall,
    areaChartData,
    scanCall,
    scanChartData,
    faxCall,
    faxChartData,
    valueSelect,
  ]);

  const { pending: currentCallPending } = currentChart.call;

  const onSelectedYearChange = useCallback(
    year => {
      const newStartDate = getStartDate(year);
      const newEndDate = getEndDate(year, currentDate);

      setSelectedYear(year);
      allCalls.jobTelemetryApiCall.makeApiCall({
        startDate: newStartDate,
        endDate: newEndDate,
      });
    },
    [allCalls, currentDate],
  );

  const totalUsageText = useMemo(() => {
    if (valueSelect === ChartSelectValue.Fax) {
      const totalSentStr = currentChart.data && currentChart.call?.success
        ? total.sent.toLocaleString(localization.locale, {
          maximumFractionDigits: 2,
        })
        : '--';

      const totalReceivedStr = currentChart.data && currentChart.call?.success
        ? total.received.toLocaleString(localization.locale, {
          maximumFractionDigits: 2,
        })
        : '--';

      return t('dashboard.cardAnalytics.totalUsage.pagesFaxedNew2', {
        sent: totalSentStr,
        received: totalReceivedStr,
      })?.replace('{{tagBreakLine}}', '</br>');
    }

    const isPrintArea = valueSelect === ChartSelectValue.PrintArea;
    const totalStr = currentChart.call?.error || currentChart.call?.pending
      ? '--'
      : formatValueToLocale({
        isPrintArea,
        data: currentChart.data,
        locale: localization.locale,
        total,
      });

    switch (valueSelect) {
      case ChartSelectValue.Print:
        return t('dashboard.cardAnalytics.totalUsage.totalPagesPrintedNew', {
          total: totalStr,
          unit,
        });
      case ChartSelectValue.Scan:
        return t('dashboard.cardAnalytics.scan.totalPagesScannedNew', {
          total: totalStr,
        });
      default: {
        const headerStr = isSquareFeet
          ? 'dashboard.cardAnalytics.totalUsage.totalAreaPrinted'
          : 'dashboard.cardAnalytics.totalUsage.totalAreaPrintedMeter';

        return t(headerStr, {
          year: selectedYear,
          total: totalStr,
          unit,
        });
      }
    }
  }, [
    valueSelect,
    currentChart.call?.error,
    currentChart.call?.pending,
    currentChart.data,
    currentChart.call?.success,
    localization.locale,
    total,
    t,
    unit,
    isSquareFeet,
    selectedYear,
  ]);

  const { hasLastDataReceivedOn } = currentChart?.data || {};

  const { hasLastDataReceivedOn: hasLastDataReceivedOnPrint } = printChartData || {};
  const { hasLastDataReceivedOn: hasLastDataReceivedOnScan } = scanChartData || {};
  const { hasLastDataReceivedOn: hasLastDataReceivedOnFax } = faxChartData || {};
  const { hasLastDataReceivedOn: hasLastDataReceivedOnArea } = areaChartData || {};

  const allLastDataReceivedOnNull = useMemo(
    () => !hasLastDataReceivedOnPrint
      && !hasLastDataReceivedOnScan
      && !hasLastDataReceivedOnFax
      && !hasLastDataReceivedOnArea,
    [
      hasLastDataReceivedOnArea,
      hasLastDataReceivedOnFax,
      hasLastDataReceivedOnPrint,
      hasLastDataReceivedOnScan,
    ],
  );

  useEffect(() => {
    const getCurrentChart = () => {
      if (hasLastDataReceivedOnPrint || errorPrint) {
        return ChartSelectValue.Print;
      }
      if (hasLastDataReceivedOnArea || errorPrintArea) {
        return ChartSelectValue.PrintArea;
      }
      if (hasLastDataReceivedOnScan || errorScan) {
        return ChartSelectValue.Scan;
      }
      if (hasLastDataReceivedOnFax || errorFax) {
        return ChartSelectValue.Fax;
      }
      return ChartSelectValue.Print;
    };

    if (!telemetryIsPending && dashboardHideSegmentedControl) {
      setValueSelect(getCurrentChart());
    }
    if (telemetryIsPending) {
      setIndexSelect(0);
      setValueSelect(0);
    }
  }, [
    dashboardHideSegmentedControl,
    errorFax,
    errorPrint,
    errorPrintArea,
    errorScan,
    hasLastDataReceivedOnArea,
    hasLastDataReceivedOnFax,
    hasLastDataReceivedOnPrint,
    hasLastDataReceivedOnScan,
    telemetryIsPending,
  ]);

  const onValueSelectChange = useCallback(
    ({ currentTarget }, index) => {
      setIndexSelect(index);
      if (!dashboardHideSegmentedControl) {
        if (!hasLastDataReceivedOnArea && index > 0) {
          setValueSelect(index + 1);
          return;
        }
        setValueSelect(index);
        return;
      }

      const { id } = currentTarget || {};
      switch (id) {
        case SegmentsTypes.Print:
          publishEvent(UsageDataPrintUsageButton);
          setValueSelect(ChartSelectValue.Print);
          break;
        case SegmentsTypes.PrintArea:
          publishEvent(UsageDataPrintAreaUsageButton);
          setValueSelect(ChartSelectValue.PrintArea);
          break;
        case SegmentsTypes.Scan:
          publishEvent(UsageDataScanUsageButton);
          setValueSelect(ChartSelectValue.Scan);
          break;
        case SegmentsTypes.Fax:
          publishEvent(UsageDataFaxUsageButton);
          setValueSelect(ChartSelectValue.Fax);
          break;
        default:
          setValueSelect(ChartSelectValue.Print);
      }
    },
    [dashboardHideSegmentedControl, hasLastDataReceivedOnArea],
  );

  const informationIcon = useMemo(
    () => (
      <Tooltip
        arrow
        content={(
          <HelpTooltipText data-testid="usage-data-help-tooltip-text">
            <span>{t('dashboard.cardAnalytics.newHelpTooltipText2')}</span>
          </HelpTooltipText>
        )}
        id="tooltip"
        placement="bottom"
        userSelect="select"
      >
        <IconInfo
          filled
          color="gray6"
          size={20}
          data-testid="usage-data-help-icon"
        />
      </Tooltip>
    ),
    [t],
  );

  useEffect(() => {
    switch (valueSelect) {
      case ChartSelectValue.Print:
        setSubtitleText(t('dashboard.cardAnalytics.subtitleText.printPageNew'));
        setUnit(t('dashboard.cardAnalytics.totalUsage.unitPages'));
        break;
      case ChartSelectValue.PrintArea:
        setSubtitleText(t('dashboard.cardAnalytics.subtitleText.printAreaNew'));
        setUnit(
          isSquareFeet
            ? t('dashboard.cardAnalytics.totalUsage.unitSqft')
            : t('dashboard.cardAnalytics.totalUsage.unitSqm'),
        );

        break;
      case ChartSelectValue.Scan:
        setSubtitleText(t('dashboard.cardAnalytics.subtitleText.scanNew'));
        setUnit(t('dashboard.cardAnalytics.totalUsage.unitPages'));
        break;
      case ChartSelectValue.Fax:
        setSubtitleText(t('dashboard.cardAnalytics.subtitleText.faxNew'));
        setUnit(null);
        break;
      default:
        break;
    }
  }, [valueSelect, isSquareFeet, total, t]);

  const abbrMonths = useMemo(
    () => [
      t('dashboard.months.jan'),
      t('dashboard.months.feb'),
      t('dashboard.months.mar'),
      t('dashboard.months.apr'),
      t('dashboard.months.mayAbbr'),
      t('dashboard.months.jun'),
      t('dashboard.months.jul'),
      t('dashboard.months.aug'),
      t('dashboard.months.sep'),
      t('dashboard.months.oct'),
      t('dashboard.months.nov'),
      t('dashboard.months.dec'),
    ],
    [t],
  );

  const months = useMemo(
    () => [
      t('dashboard.months.january'),
      t('dashboard.months.february'),
      t('dashboard.months.march'),
      t('dashboard.months.april'),
      t('dashboard.months.may'),
      t('dashboard.months.june'),
      t('dashboard.months.july'),
      t('dashboard.months.august'),
      t('dashboard.months.september'),
      t('dashboard.months.october'),
      t('dashboard.months.november'),
      t('dashboard.months.december'),
    ],
    [t],
  );

  const chart = useMemo(() => {
    // Helper functions to avoid duplicating pending, error and retry logic in this useEffect.
    const isPending = chartCall => chartCall.pending || tenancyRegistryCall.pending;
    const hasError = chartCall => !!(chartCall.error || tenancyRegistryCall.error);
    const onRetry = chartCall => {
      chartCall.makeApiCall();

      if (tenancyRegistryCall.error) {
        tenancyRegistryCall.makeApiCall();
      }
    };

    switch (valueSelect) {
      case ChartSelectValue.Print:
        return (
          <PrintPageChart
            chartData={printChartData}
            chartWithoutAverage={chartWithoutAverage}
            error={hasError(printCall)}
            onRetry={() => onRetry(printCall)}
            loading={isPending(printCall)}
            selectedYear={selectedYear}
            pointStartAverage={pointStartAverage}
            numMonthsAverage={numMonthsAverage}
            i18n={{
              legend: {
                average: t('dashboard.cardAnalytics.average.usage'),
                color: t('dashboard.cardAnalytics.color.color'),
                blackAndWhite: t('dashboard.cardAnalytics.color.blackWhite'),
              },
              yAxis: t('dashboard.cardAnalytics.printedPages'),
              xAxis: {
                categories: abbrMonths,
              },
              tooltip: {
                categories: months,
                averageTitleFormatter: () => `${selectedYear}`,
                total: t('dashboard.total'),
                unit: t('dashboard.cardAnalytics.totalUsage.unitPages'),
              },
              error: t('dashboard.error.bodyCopy'),
              retry: t('dashboard.error.retry'),
            }}
          />
        );
      case ChartSelectValue.PrintArea:
        return (
          <PrintAreaChart
            chartData={areaChartData}
            chartWithoutAverage={chartWithoutAverage}
            error={hasError(printAreaCall)}
            onRetry={() => onRetry(printAreaCall)}
            loading={isPending(printAreaCall)}
            isSquareFeet={isSquareFeet}
            selectedYear={selectedYear}
            pointStartAverage={pointStartAverage}
            numMonthsAverage={numMonthsAverage}
            i18n={{
              unit: {
                sqft: t('dashboard.cardAnalytics.printAreaSquareFeet'),
                sqm: t('dashboard.cardAnalytics.printAreaSquareMeter'),
              },
              legend: {
                average: t('dashboard.cardAnalytics.average.usage'),
                premium: t('dashboard.cardAnalytics.types.premiumQuality'),
                highDensity: t('dashboard.cardAnalytics.types.highDensity'),
                lowDensity: t('dashboard.cardAnalytics.types.lowDensity'),
                colorLines: t('dashboard.cardAnalytics.types.colorLines'),
                monoLines: t('dashboard.cardAnalytics.types.monoLines'),
                blueprint: t('dashboard.cardAnalytics.types.blueprint'),
              },
              xAxis: {
                categories: abbrMonths,
              },
              tooltip: {
                categories: months,
                averageTitleFormatter: () => `${selectedYear}`,
                totalUnit: isSquareFeet
                  ? t('dashboard.cardAnalytics.totalUsage.squareFeet')
                  : t(
                    'dashboard.cardAnalytics.totalUsage.totalAreaPrintedMeterFull',
                  ),
                unit: isSquareFeet
                  ? t('dashboard.cardAnalytics.totalUsage.unitSqft')
                  : t('dashboard.cardAnalytics.totalUsage.unitSqm'),
                total: t('dashboard.total'),
              },
              error: t('dashboard.error.bodyCopy'),
              retry: t('dashboard.error.retry'),
            }}
          />
        );
      case ChartSelectValue.Scan:
        return (
          <ScanChart
            chartData={scanChartData}
            chartWithoutAverage={chartWithoutAverage}
            error={hasError(scanCall)}
            onRetry={() => onRetry(scanCall)}
            loading={isPending(scanCall)}
            selectedYear={selectedYear}
            pointStartAverage={pointStartAverage}
            numMonthsAverage={numMonthsAverage}
            i18n={{
              legend: {
                scans: t('dashboard.cardAnalytics.select.scans'),
                average: t('dashboard.cardAnalytics.average.usage'),
              },
              yAxis: t('dashboard.cardAnalytics.scan.title'),
              xAxis: {
                categories: abbrMonths,
              },
              tooltip: {
                categories: months,
                scanLabel: t('dashboard.cardAnalytics.scan.title'),
                averageTitleFormatter: () => `${selectedYear}`,
                unit: t('dashboard.cardAnalytics.totalUsage.unitPages'),
              },
              error: t('dashboard.error.bodyCopy'),
              retry: t('dashboard.error.retry'),
            }}
          />
        );
      case ChartSelectValue.Fax:
        return (
          <FaxChart
            chartData={faxChartData}
            chartWithoutAverage={chartWithoutAverage}
            error={hasError(faxCall)}
            onRetry={() => onRetry(faxCall)}
            loading={isPending(faxCall)}
            selectedYear={selectedYear}
            pointStartAverage={pointStartAverage}
            numMonthsAverage={numMonthsAverage}
            i18n={{
              legend: {
                sent: t('dashboard.cardAnalytics.fax.sent'),
                received: t('dashboard.cardAnalytics.fax.received'),
                averageSent: t('dashboard.cardAnalytics.average.sent'),
                averageReceived: t('dashboard.cardAnalytics.average.received'),
              },
              yAxis: t('dashboard.cardAnalytics.fax.title'),
              xAxis: {
                categories: abbrMonths,
              },
              tooltip: {
                categories: months,
                averageTitleFormatter: () => `${selectedYear}`,
              },
              error: t('dashboard.error.bodyCopy'),
              retry: t('dashboard.error.retry'),
              total: t('dashboard.total'),
            }}
          />
        );
      default:
        return <></>;
    }
  }, [
    abbrMonths,
    areaChartData,
    chartWithoutAverage,
    faxCall,
    faxChartData,
    isSquareFeet,
    months,
    numMonthsAverage,
    pointStartAverage,
    printAreaCall,
    printCall,
    printChartData,
    scanCall,
    scanChartData,
    selectedYear,
    t,
    tenancyRegistryCall,
    valueSelect,
  ]);

  const togglePartial = useMemo(
    () => (
      <Toggle
        id="toggle-partial"
        data-testid="usage-toggle"
        selectedItem={indexSelect}
        onChange={(event, index) => onValueSelectChange(event, index)}
      >
        <SegmentedControlItem
          id="print-page-partial"
          label={t('dashboard.cardAnalytics.select.print')}
        />
        <SegmentedControlItem
          id="scan-partial"
          label={t('dashboard.cardAnalytics.select.scan')}
        />
        <SegmentedControlItem
          id="fax-partial"
          label={t('dashboard.cardAnalytics.select.fax')}
        />
      </Toggle>
    ),
    [indexSelect, onValueSelectChange, t],
  );

  const toggleFull = useMemo(
    () => (
      <Toggle
        id="toggle-full"
        data-testid="usage-toggle"
        selectedItem={indexSelect}
        onChange={(event, index) => onValueSelectChange(event, index)}
      >
        <SegmentedControlItem
          id="print-page-full"
          label={t('dashboard.cardAnalytics.select.printPage')}
        />
        <SegmentedControlItem
          id="print-area-full"
          label={t('dashboard.cardAnalytics.select.printArea')}
        />
        <SegmentedControlItem
          id="scan-full"
          label={t('dashboard.cardAnalytics.select.scan')}
        />
        <SegmentedControlItem
          id="fax-full"
          label={t('dashboard.cardAnalytics.select.fax')}
        />
      </Toggle>
    ),
    [t, indexSelect, onValueSelectChange],
  );

  const allRequestsFailedOrNoDataYet = (!hasLastDataReceivedOn
      && !currentChart.call.error
      && dashboardShowNoDataYet)
    || allRequestsFailed;

  const subHeader = useMemo(() => {
    const rowReverse = (allRequestsFailedOrNoDataYet && !currentChart.call.pending)
      || telemetryIsPending;

    return (
      <SubHeader flexDirectionRowReverse={rowReverse}>
        {(!allRequestsFailedOrNoDataYet || currentChart.call.pending)
          && !telemetryIsPending && (
            <div>
              <SmartTooltip content={<p>{subtitleText}</p>}>
                <SubHeaderTitle>
                  <p>{subtitleText}</p>
                </SubHeaderTitle>
              </SmartTooltip>
              <TotalUsage
                className="subtitle-regular"
                dataTestid="total-usage"
                text={totalUsageText}
                tagReplaceStart="<span class='title-small'>"
                tagReplaceEnd="</span>"
              />
            </div>
        )}
        <SelectYear
          currentYear={currentDate.getFullYear()}
          selectedYear={selectedYear}
          setSelectedYear={onSelectedYearChange}
          disabled={currentChart.call.pending || tenancyRegistryCall.pending}
          createdOrgYear={
            createdOrgDate?.getFullYear() || currentDate.getFullYear()
          }
        />
      </SubHeader>
    );
  }, [
    allRequestsFailedOrNoDataYet,
    createdOrgDate,
    currentChart.call.pending,
    currentDate,
    onSelectedYearChange,
    selectedYear,
    subtitleText,
    telemetryIsPending,
    tenancyRegistryCall.pending,
    totalUsageText,
  ]);

  const failedAllRequestsWidget = useMemo(
    () => (
      <ErrorContainer
        data-testid="usage-data-error-container"
        heightAllContainer={isCurrentYearEqualYearCreateOrg}
      >
        <ErrorWidget
          message={t('dashboard.error.bodyCopy')}
          labelRetry={t('dashboard.error.retry')}
          onRetry={() => onSelectedYearChange(selectedYear)}
          dataTestId="usage-data-error-widget"
        />
      </ErrorContainer>
    ),
    [isCurrentYearEqualYearCreateOrg, onSelectedYearChange, selectedYear, t],
  );

  const loadingTelemetry = useMemo(
    () => (
      <LoaderContainer heightAllContainer={isCurrentYearEqualYearCreateOrg}>
        <LoaderWidget fullScreen dataTestId="loader-widget" />
      </LoaderContainer>
    ),
    [isCurrentYearEqualYearCreateOrg],
  );

  const showDataYet = !allRequestsFailed
    && !hasLastDataReceivedOn
    && dashboardShowNoDataYet
    && !currentCallPending
    && !currentChart.call?.error;
  const showChart = !allRequestsFailed && (!showDataYet || currentCallPending);
  const heightNoDataAvailable = useMemo(() => {
    if (
      isCurrentYearEqualYearCreateOrg
      && (!allLastDataReceivedOnNull || errorJobTelemetry)
    ) {
      return '366px';
    }
    if (allLastDataReceivedOnNull && isCurrentYearEqualYearCreateOrg) {
      return '424px';
    }
    return '250px';
  }, [
    allLastDataReceivedOnNull,
    isCurrentYearEqualYearCreateOrg,
    errorJobTelemetry,
  ]);

  const content = useMemo(
    () => (
      <GenericThemeProvider density="high">
        <Card
          header={(
            <HeaderContainer>
              <HeaderArea
                leftArea={(
                  <Info>
                    <HeaderTitle
                      className="subtitle-regular"
                      data-testid="usage-data-header"
                    >
                      {t('dashboard.cardAnalytics.headerNew2')}
                    </HeaderTitle>
                    {informationIcon}
                  </Info>
                )}
                rightArea={(
                  <ViewDetailsButton
                    appearance="ghost"
                    small
                    onClick={() => {
                      publishEvent(UsageDataViewUsageDetailsButton);
                      setShowDetailedUsageModal(true);
                    }}
                    data-testid="detailed-usage-button"
                    disabled={
                      dashboardShowNoDataYet
                      && (allRequestsFailed || allLastDataReceivedOnNull)
                    }
                  >
                    {t('dashboard.cardAnalytics.select.detailedUsageButton')}
                  </ViewDetailsButton>
                )}
              />
            </HeaderContainer>
          )}
        >
          {(!isCurrentYearEqualYearCreateOrg
            || !allRequestsFailedOrNoDataYet)
            && subHeader}
          {telemetryIsPending && loadingTelemetry}

          {!telemetryIsPending && (
            <>
              <>
                {allRequestsFailed && failedAllRequestsWidget}

                {showDataYet && (
                  <NoDataAvailable
                    i18n={{
                      title: t('dashboard.cardAnalytics.noDataAvailable.title'),
                      subTitle: t(
                        'dashboard.cardAnalytics.noDataAvailable.subTitle',
                      ),
                    }}
                    heightNoDataAvailable={heightNoDataAvailable}
                    icon={<IconWarning size={24} color="gray7" />}
                  />
                )}

                {showChart && chart}
              </>

              {!allRequestsFailed
                && (!allLastDataReceivedOnNull || errorJobTelemetry) && (
                  <>
                    {!dashboardHideSegmentedControl && (
                      <ToggleContainer>
                        {hasLastDataReceivedOnArea && toggleFull}
                        {!hasLastDataReceivedOnArea && togglePartial}
                      </ToggleContainer>
                    )}
                    {dashboardHideSegmentedControl && (
                      <ToggleContainer>
                        <ToggleUsageData
                          errorPrint={!!errorPrint}
                          errorPrintArea={!!errorPrintArea}
                          errorScan={!!errorScan}
                          errorFax={!!errorFax}
                          hasLastDataReceivedOnPrint={
                            hasLastDataReceivedOnPrint
                          }
                          hasLastDataReceivedOnScan={hasLastDataReceivedOnScan}
                          hasLastDataReceivedOnFax={hasLastDataReceivedOnFax}
                          hasLastDataReceivedOnArea={hasLastDataReceivedOnArea}
                          onValueSelectChange={onValueSelectChange}
                          indexSelect={indexSelect}
                        />
                      </ToggleContainer>
                    )}
                  </>
              )}
            </>
          )}
        </Card>
      </GenericThemeProvider>
    ),
    [
      allLastDataReceivedOnNull,
      allRequestsFailed,
      allRequestsFailedOrNoDataYet,
      chart,
      dashboardHideSegmentedControl,
      dashboardShowNoDataYet,
      errorFax,
      errorJobTelemetry,
      errorPrint,
      errorPrintArea,
      errorScan,
      failedAllRequestsWidget,
      hasLastDataReceivedOnArea,
      hasLastDataReceivedOnFax,
      hasLastDataReceivedOnPrint,
      hasLastDataReceivedOnScan,
      heightNoDataAvailable,
      indexSelect,
      informationIcon,
      isCurrentYearEqualYearCreateOrg,
      loadingTelemetry,
      onValueSelectChange,
      showChart,
      showDataYet,
      subHeader,
      t,
      telemetryIsPending,
      toggleFull,
      togglePartial,
    ],
  );

  const currentStartAndEndDate = useMemo(
    () => ({
      startDate: getStartDate(selectedYear),
      endDate: getEndDate(selectedYear, currentDate),
    }),
    [currentDate, selectedYear],
  );

  const currentTelemetryData = useMemo(
    () => (telemetryIsPending
      ? {}
      : parseDetailedUsageData({
        faxCall,
        printAreaCall,
        printCall,
        scanCall,
        isSquareFeet,
        ...currentStartAndEndDate,
        numMonths,
      })),
    [
      currentStartAndEndDate,
      faxCall,
      isSquareFeet,
      numMonths,
      printAreaCall,
      printCall,
      scanCall,
      telemetryIsPending,
    ],
  );

  return (
    <>
      <Container data-testid={dataTestId}>
        <CardContainer appearance="dropShadow" content={content} />
      </Container>
      {showDetailedUsageModal && (
        <DetailedUsageDataModal
          locale={localization?.locale}
          data-testid="usage-data-detailed-usage-modal"
          show={showDetailedUsageModal}
          currentYear={currentDate.getFullYear()}
          onClose={() => setShowDetailedUsageModal(false)}
          currentDate={currentDate}
          isSquareFeet={isSquareFeet}
          currentTelemetryData={currentTelemetryData}
          currentYearIsEqualSelectedYear={currentYearIsEqualSelectedYear}
        />
      )}
    </>
  );
};

HPUsageDataWidget.defaultProps = {
  'data-testid': null,
};

HPUsageDataWidget.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  country: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};

export default HPUsageDataWidget;
