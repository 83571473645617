import styled from 'styled-components';

export const Container = styled.div`
  display: inline;
`;

export const ContainerText = styled.div`
  color: ${({ theme }) => theme.color.foreground.default};
  margin: 32px 36px auto;
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 0;
`;
