import { useCallback } from 'react';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import getAccount from '../services/getAccount';

const useAccountApiCall = ({ init }) => {
  const { shell, stack } = useRootContext();
  const { authProvider } = shell;

  const apiCall = useCallback(
    () => getAccount(
      { stack, authProvider },
    ).then(({ data }) => data),
    [authProvider, stack],
  );

  return useApiCall({
    initialData: null,
    apiCall,
    init,
  });
};

export default useAccountApiCall;
