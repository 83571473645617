import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LoaderWidget, RootProvider } from '@jarvis/react-portal-addons';
import resources from './assets/locale/index';
import {
  getLDClientSideID,
  getUniqueUserKey,
} from './utils/ldClient';
import DashboardComponent from './components/Dashboard/index';

const Dashboard = props => {
  const [LDProvider, setLDProvider] = useState(null);
  const [fetchingLD, setFetchingLD] = useState(true);
  const [errorLD, setErrorLD] = useState(false);

  const { shell, stack } = props;

  const localization = shell?.localization || {};
  const { locale } = localization;

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const Provider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(locale),
          },
        });
        setLDProvider(() => Provider);
      } catch (err) {
        console.error(err);
        setErrorLD(true);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  if (errorLD) {
    return (
      <RootProvider
        stack={stack}
        shell={shell}
        localization={localization}
        resources={resources}
      >
        <DashboardComponent {...props} />
      </RootProvider>
    );
  }

  return (
    <LDProvider>
      <RootProvider
        stack={stack}
        shell={shell}
        localization={localization}
        resources={resources}
      >
        <DashboardComponent {...props} />
      </RootProvider>
    </LDProvider>
  );
};

Dashboard.defaultProps = {
  shell: {},
};

Dashboard.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number.isRequired,
};

export default Dashboard;
