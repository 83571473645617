import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import { SegmentedControlItem } from '@veneer/core';
import { Toggle } from './styles';

const ToggleUsageData = ({
  errorPrint,
  errorPrintArea,
  errorScan,
  errorFax,
  hasLastDataReceivedOnPrint,
  hasLastDataReceivedOnScan,
  hasLastDataReceivedOnFax,
  hasLastDataReceivedOnArea,
  onValueSelectChange,
  indexSelect,
}) => {
  const { t } = useI18n();

  const dataTypes = [
    hasLastDataReceivedOnPrint || errorPrint,
    hasLastDataReceivedOnArea || errorPrintArea,
    hasLastDataReceivedOnScan || errorScan,
    hasLastDataReceivedOnFax || errorFax,
  ].filter(Boolean);

  const showPrint = dataTypes.length > 1 && (hasLastDataReceivedOnPrint || errorPrint);
  const showArea = dataTypes.length > 1 && (hasLastDataReceivedOnArea || errorPrintArea);
  const showScan = dataTypes.length > 1 && (hasLastDataReceivedOnScan || errorScan);
  const showFax = dataTypes.length > 1 && (hasLastDataReceivedOnFax || errorFax);

  return (
    <Toggle
      id="toggle-full"
      data-testid="usage-toggle"
      selectedItem={indexSelect}
      onChange={(event, index) => onValueSelectChange(event, index)}
    >
      {showPrint && (
        <SegmentedControlItem
          id="print-page"
          label={showArea
            ? t('dashboard.cardAnalytics.select.printPage')
            : t('dashboard.cardAnalytics.select.print')}
        />
      )}

      {showArea && (
        <SegmentedControlItem
          id="print-area"
          label={t('dashboard.cardAnalytics.select.printArea')}
        />
      )}

      {showScan && (
        <SegmentedControlItem
          id="scan"
          label={t('dashboard.cardAnalytics.select.scan')}
        />
      )}

      {showFax && (
        <SegmentedControlItem
          id="fax"
          label={t('dashboard.cardAnalytics.select.fax')}
        />
      )}
    </Toggle>
  );
};

ToggleUsageData.defaultProps = {
  errorPrint: false,
  errorPrintArea: false,
  errorScan: false,
  errorFax: false,
};

ToggleUsageData.propTypes = {
  errorPrint: PropTypes.bool,
  errorPrintArea: PropTypes.bool,
  errorScan: PropTypes.bool,
  errorFax: PropTypes.bool,
  hasLastDataReceivedOnPrint: PropTypes.bool.isRequired,
  hasLastDataReceivedOnScan: PropTypes.bool.isRequired,
  hasLastDataReceivedOnFax: PropTypes.bool.isRequired,
  hasLastDataReceivedOnArea: PropTypes.bool.isRequired,
  onValueSelectChange: PropTypes.func.isRequired,
  indexSelect: PropTypes.number.isRequired,
};

export default ToggleUsageData;
