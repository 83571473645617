import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import { Container } from './styles';

const Welcome = ({ className, name }) => {
  const { t } = useI18n();

  return (
    <Container className={className} data-testid="welcome">
      {t('dashboard.header.welcome.option1', {
        name,
        interpolation: { escapeValue: false },
      })}
    </Container>
  );
};

Welcome.defaultProps = {
  className: null,
};

Welcome.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default Welcome;
