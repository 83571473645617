import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 154px;
  width: 100%;
  ${({ isHpPlus }) => (isHpPlus
    ? 'background: linear-gradient(90.83deg, #D06702 -2.27%, #EB7722 100.12%);'
    : 'background: linear-gradient(105.32deg, #027AAE 13.74%, #3AB4EB 100%);'
  )}

  ${mixins.md(`
    height: 170px;
  `)}

  ${mixins.lg(`
    height: 178px;
  `)}
`;

export default Container;
