import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.orbitalTwo};
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ImageArea = styled.div`
  position: relative;
`;

export const Image = styled.div`
  height: 48px;
  width: 48px;
  img {
    height: 48px;
    width: 48px;
  }
`;

export const HPPlusIcon = styled.div`
  img {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const SubName = styled.p`
  color: ${({ theme }) => theme.color.foreground.light};
`;
