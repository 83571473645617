import { Stack } from '@jarvis/web-stratus-client';

const getPrintAnywhereStacks = ({ version = 1 } = {}) => ({
  [Stack.dev]: `https://api-rsantos2.goblin.hpalpine.com/api/v${version}/`,
  [Stack.pie]: `https://api-rsantos2.goblin.hpalpine.com/api/v${version}/`,
  [Stack.stage]: `https://api-staging.ogre.hpalpine.com/api/v${version}/`,
  [Stack.prod]: `https://roam.api.hp.com/api/v${version}/`,
});

export default getPrintAnywhereStacks;
