import { mixins } from '@jarvis/react-portal-addons';
import styled from 'styled-components';
import primitives from '@veneer/primitives';
import SMBMainTheme from '../../styles/styles';

export const Container = styled.div`
  @media (max-width: ${primitives.layout.breakpoint4}){
    display: block;
  }
  @media ${SMBMainTheme.mediaQueries.desktop}, ${SMBMainTheme.mediaQueries.large}, ${SMBMainTheme.mediaQueries.xLarge}{
    display: flex;
  }
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ContainerCard = styled.div`
  margin: auto;
  height: 256px;
  ${mixins.xs(`
    height: auto;
    min-height: 256px;
  `)}
  ${mixins.lg('height: 319px;')}
  ${mixins.xx('height: 256px;')}
`;

export const Line = styled.div`
  background: ${primitives.color.gray1};
  border-radius: 1.5px;
  height: 3px;
  width:  calc(100% - 48px);
  margin: 24px;
  @media ${SMBMainTheme.mediaQueries.large}{
    height: 315px;
    width: 3px;
    margin: 0;
  }
  @media ${SMBMainTheme.mediaQueries.desktop}, ${SMBMainTheme.mediaQueries.xLarge} {
    height: 264px;
    width: 3px;
    margin: 0;
  }
`;

export const HeaderText = styled.h6`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.color.foreground.default};
`;
