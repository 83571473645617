import React from 'react';
import PropTypes from 'prop-types';
import { ErrorWidget, LoaderWidget } from '@jarvis/react-portal-addons';
import {
  Container,
  ContainerCard,
  HeaderText,
  Line,
} from './styles';

const CardContainer = ({
  content,
  'data-testid': dataTestid,
  headerText,
  retryLoad,
  showError,
  showLine,
  showLoading,
}) => {
  const ContentRender = () => {
    if (showError === true) {
      return (
        <ErrorWidget onRetry={() => retryLoad()} />
      );
    }
    if (showLoading === true) {
      return (
        <LoaderWidget fullScreen />
      );
    }
    return content;
  };

  return (
    <Container data-testid={dataTestid}>
      <ContainerCard>
        <HeaderText className="subtitle-regular" data-testid={`${dataTestid}-header`}>
          {headerText}
        </HeaderText>
        {ContentRender()}
      </ContainerCard>
      {showLine && (
        <Line />
      )}
    </Container>
  );
};

CardContainer.propTypes = {
  content: PropTypes.node,
  'data-testid': PropTypes.string,
  headerText: PropTypes.string.isRequired,
  retryLoad: PropTypes.func,
  showError: PropTypes.bool,
  showLine: PropTypes.bool,
  showLoading: PropTypes.bool,
};

CardContainer.defaultProps = {
  'data-testid': 'card-container',
  content: null,
  retryLoad: () => {},
  showError: false,
  showLine: false,
  showLoading: false,
};

export default CardContainer;
