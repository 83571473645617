import { Button } from '@veneer/core';
import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';

export const Container = styled.ul`
  display: flex;
  align-items: center;
  height:36px;

  ${mixins.xs(`
    justify-content: center;
  `)}
`;

export const Chevron = styled(Button)`
  &&:not(:disabled) {
    ${({ theme }) => theme.color.primary.base.default};
  }
`;

export const SelectedValue = styled.span`
  display: flex;
  margin: 2.5px 14px 0 14px;
`;
