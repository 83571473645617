import { RelationshipsClient } from '@jarvis/web-stratus-client';

export default async function getTenancyNode({
  authProvider,
  stack,
  id,
}) {
  const relationshipsClient = new RelationshipsClient(stack, authProvider);
  const response = await relationshipsClient.getNode({ nodeId: id });

  return response;
}
