import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  IconChevronLeft,
  IconChevronRight,
} from '@veneer/core';
import { UsageDataNextYearButton, UsageDataPreviousYearButton, publishEvent } from '../../utils/analytics';
import { Chevron, Container, SelectedValue } from './styles';

const SelectYear = ({
  currentYear,
  selectedYear,
  setSelectedYear,
  disabled,
  createdOrgYear,
}) => {
  const shouldDisableChevron = useCallback(side => {
    if (disabled) {
      return true;
    }

    if (side === 'left' && selectedYear - 1 < createdOrgYear) {
      return true;
    }

    if (side === 'right' && selectedYear + 1 > currentYear) {
      return true;
    }

    return false;
  }, [
    createdOrgYear,
    currentYear,
    disabled,
    selectedYear,
  ]);

  const leftChevronDisabled = useMemo(() => shouldDisableChevron('left'), [shouldDisableChevron]);
  const rightChevronDisabled = useMemo(() => shouldDisableChevron('right'), [shouldDisableChevron]);

  return (
    <Container data-testid="usage-data-select-year">
      <nav>
        <Container>
          <li>
            <Chevron
              appearance="ghost"
              data-testid="usage-data-select-year-previous"
              disabled={leftChevronDisabled}
              leadingIcon={<IconChevronLeft />}
              onClick={() => {
                publishEvent(UsageDataPreviousYearButton);
                setSelectedYear(selectedYear - 1);
              }}
              small
            />
          </li>
          <li>
            <SelectedValue data-testid="usage-data-select-value" className="label">{selectedYear}</SelectedValue>
          </li>
          <li>
            <Chevron
              appearance="ghost"
              data-testid="usage-data-select-year-next"
              disabled={rightChevronDisabled}
              leadingIcon={<IconChevronRight />}
              onClick={() => {
                publishEvent(UsageDataNextYearButton);
                setSelectedYear(selectedYear + 1);
              }}
              small
            />
          </li>
        </Container>
      </nav>
    </Container>
  );
};

SelectYear.propTypes = {
  currentYear: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
  setSelectedYear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  createdOrgYear: PropTypes.number.isRequired,
};

SelectYear.defaultProps = {
  disabled: false,
};

export default SelectYear;
