import { useCallback, useMemo } from 'react';
import {
  buildChartData,
  usageChartUtils,
  useCallSuccess,
  useJobTelemetryApiCall,
  useTenancyRegistryCall,
} from '@jarvis/react-portal-addons';

const { SQCM_TO_SQFT, SQCM_TO_SQM } = usageChartUtils;
const {
  buildFaxChartData,
  buildPrintAreaChartData,
  buildPrintPagesChartData,
  buildScanChartData,
  getNumMonthsAverage,
  ViewType,
} = buildChartData;

const useCalls = ({
  authProvider,
  orgSelector,
  stack,
  params,
  isSquareFeet,
  numMonths,
  numMonthsAverage,
  selectedYear,
  currentDate,
  createdOrgDate,
  setAreaChartData,
  setPrintChartData,
  setScanChartData,
  setFaxChartData,
  setNumMonthsAverage,
  setCreatedOrgDate,
}) => {
  const jobTelemetryApiCall = useJobTelemetryApiCall({
    authProvider,
    stack,
    params,
  });

  const tenancyRegistryCall = useTenancyRegistryCall({
    authProvider,
    stack,
    id: orgSelector?.getOrgTenantId(),
  });

  const onTenancyRegistrySuccess = useCallback(data => {
    const orgDate = new Date(data?.dateCreated * 1000);

    setNumMonthsAverage(getNumMonthsAverage({
      currentDate,
      selectedYear,
      createOrgDate: orgDate,
    }));
    setCreatedOrgDate(orgDate);
  }, [
    selectedYear,
    currentDate,
    setNumMonthsAverage,
    setCreatedOrgDate,
  ]);

  useCallSuccess({
    call: tenancyRegistryCall,
    onSuccess: onTenancyRegistrySuccess,
  });

  const canRunTelemetryCalls = tenancyRegistryCall.success && !tenancyRegistryCall.pending && !!createdOrgDate;

  const {
    print: printCall,
    printArea: printAreaCall,
    scan: scanCall,
    fax: faxCall,
  } = jobTelemetryApiCall.data;

  const onPrintAreaSuccess = useCallback(data => {
    const unit = isSquareFeet ? SQCM_TO_SQFT : SQCM_TO_SQM;
    const areaData = buildPrintAreaChartData({
      payload: data,
      totalRange: numMonths,
      unit,
      rangeToAverage: numMonthsAverage,
      startDate: new Date(params.startDate),
      endDate: new Date(params.endDate),
      viewType: ViewType.Monthly,
    });
    setAreaChartData(areaData);
  }, [isSquareFeet, numMonths, numMonthsAverage, setAreaChartData, params.endDate, params.startDate]);

  useCallSuccess({
    init: canRunTelemetryCalls,
    call: printAreaCall,
    onSuccess: onPrintAreaSuccess,
  });

  const onPrintCallSuccess = useCallback(data => {
    const printData = buildPrintPagesChartData({
      payload: data,
      totalRange: numMonths,
      rangeToAverage: numMonthsAverage,
      startDate: new Date(params.startDate),
      endDate: new Date(params.endDate),
      viewType: ViewType.Monthly,
    });
    setPrintChartData(printData);
  }, [numMonths, numMonthsAverage, setPrintChartData, params.endDate, params.startDate]);

  useCallSuccess({
    init: canRunTelemetryCalls,
    call: printCall,
    onSuccess: onPrintCallSuccess,
  });

  const onScanCallSuccess = useCallback(data => {
    const scanData = buildScanChartData({
      payload: data,
      totalRange: numMonths,
      rangeToAverage: numMonthsAverage,
      startDate: new Date(params.startDate),
      endDate: new Date(params.endDate),
      viewType: ViewType.Monthly,
    });
    setScanChartData(scanData);
  }, [numMonths, numMonthsAverage, setScanChartData, params.endDate, params.startDate]);

  useCallSuccess({
    init: canRunTelemetryCalls,
    call: scanCall,
    onSuccess: onScanCallSuccess,
  });

  const onFaxCallSuccess = useCallback(data => {
    const faxData = buildFaxChartData({
      payload: data,
      totalRange: numMonths,
      rangeToAverage: numMonthsAverage,
      startDate: new Date(params.startDate),
      endDate: new Date(params.endDate),
      viewType: ViewType.Monthly,
    });
    setFaxChartData(faxData);
  }, [numMonths, numMonthsAverage, setFaxChartData, params.endDate, params.startDate]);

  useCallSuccess({
    init: canRunTelemetryCalls,
    call: faxCall,
    onSuccess: onFaxCallSuccess,
  });

  const allCalls = useMemo(() => ({
    tenancyRegistryCall,
    jobTelemetryApiCall,
  }), [tenancyRegistryCall, jobTelemetryApiCall]);

  return {
    printCall,
    printAreaCall,
    scanCall,
    faxCall,
    tenancyRegistryCall,
    allCalls,
  };
};

export default useCalls;
