import { buildChartData, usageChartUtils } from '@jarvis/react-portal-addons';

export const parseDataPrintYear = ({ printCounters }) => printCounters?.map(printCounter => {
  const {
    counters, deviceId, lastDataReceivedOn, lastResetTime,
  } = printCounter;
  const totalPagePrinted = counters.reduce((total, item) => (total + item.impressionsCompleted), 0);
  return {
    deviceId,
    lastDataReceivedOn,
    lastResetTime,
    totalPagePrinted,
  };
});

export const parseDataScanYear = ({ scanCounters }) => scanCounters?.map(scanCounter => {
  const {
    counters, deviceId, lastDataReceivedOn, lastResetTime,
  } = scanCounter;
  const totalImages = counters.reduce((total, item) => (total + item.totalImages), 0);
  return {
    deviceId,
    lastDataReceivedOn,
    lastResetTime,
    totalImages,
  };
});

export const parseDataFaxYear = ({ faxCounters }) => faxCounters?.map(faxCounter => {
  const {
    counters, deviceId, lastDataReceivedOn, lastResetTime,
  } = faxCounter;
  const totalPageFax = counters.reduce((total, item) => ({
    sentPages: (total.sentPages + item.sentAnalogImages),
    receivedPages: (total.receivedPages + item.receivedAnalogImages),
  }), {
    sentPages: 0,
    receivedPages: 0,
  });
  return {
    deviceId,
    lastDataReceivedOn,
    lastResetTime,
    totalPageFax,
  };
});

export const parseDataPrintAreaYear = ({
  counters,
  numMonths,
  unit,
  startDate,
  endDate,
  viewType,
}) => {
  const { buildPrintAreaChartData } = buildChartData;

  return counters?.usageByPrintCategory?.map(item => {
    const buildByPrinter = buildPrintAreaChartData({
      payload: {
        ...counters,
        usageByPrintCategory: item ? [item] : [],
      },
      totalRange: numMonths,
      rangeToAverage: numMonths,
      unit,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      viewType,
    });

    return {
      deviceId: item?.deviceId,
      totalAreaPrinted: buildByPrinter?.total,
      lastDataReceivedOn: item?.lastDataReceivedOn,
      lastResetTime: item?.lastResetTime,
    };
  });
};

export const parseDetailedUsageData = ({
  faxCall,
  printCall,
  printAreaCall,
  scanCall,
  startDate,
  endDate,
  numMonths,
  isSquareFeet,
}) => {
  const { ViewType } = buildChartData;
  const { SQCM_TO_SQFT, SQCM_TO_SQM } = usageChartUtils;
  const unit = isSquareFeet ? SQCM_TO_SQFT : SQCM_TO_SQM;
  const printData = printCall?.data?.data || {};
  const faxData = faxCall?.data?.data || {};
  const printedAreaData = printAreaCall?.data?.data || {};
  const scanData = scanCall?.data?.data || {};

  const printedCounters = parseDataPrintYear(printData);
  const faxCounters = parseDataFaxYear(faxData);
  const printedAreaCounters = parseDataPrintAreaYear({
    counters: printedAreaData,
    numMonths,
    unit,
    startDate,
    endDate,
    viewType: ViewType.Yearly,
  });

  const scanCounters = parseDataScanYear(scanData);

  return {
    printedCounters,
    faxCounters,
    printedAreaCounters,
    scanCounters,
  };
};
