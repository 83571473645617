export function getStartDate(selectedYear) {
  return `${selectedYear}-01-01`;
}

export function getEndDate(selectedYear, currentDate) {
  if (selectedYear === currentDate.getFullYear()) {
    return currentDate.toISOString().split('T')[0];
  }

  return `${selectedYear}-12-31`;
}

export const SQCM_TO_SQFT = 0.00107639;
export const SQCM_TO_SQM = 0.0001;

export const STORAGE_COLUMN_DATA_USAGE = 'dashboard-column-detail-data-usage';

export const ID_AVERAGE = 'Average usage';

export const ID_AVERAGE_SENT = 'Sent avg. usage';
export const ID_AVERAGE_RECEIVED = 'Received avg. usage';

export const getPointsWithoutAverage = points => points.filter(p => p.series.userOptions?.isTooltipAverage !== true);
export const getPointsAverage = points => points.filter(p => p.series.userOptions?.isTooltipAverage === true);
export const getPointSelected = points => points?.filter(p => p.series.state === 'hover')[0];

export const formatValueToLocale = ({
  isPrintArea,
  total,
  data,
  locale,
}) => {
  const toLocaleOptions = {
    maximumFractionDigits: 2,
    ...(isPrintArea ? { minimumFractionDigits: 2 } : {}),
  };

  const totalStr = data
    ? total.toLocaleString(locale, toLocaleOptions)
    : '0.00';

  return totalStr;
};
