import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';

export default async function getAccount({
  stack,
  authProvider,
}) {
  const client = new AccountMgtSvcClient(stack, authProvider);
  const response = await client.getAccount();

  return response;
}
