import React from 'react';
import { path } from 'ramda';
import { HyperlinkText, useI18n } from '@jarvis/react-portal-addons';
import CardContainer from '../../../shared/CardContainer';
import Images from '../../../assets/images';
import {
  Container,
  ContainerImg,
  ContainerText,
} from './styles';

const ForestFirst = props => {
  const navigation = path(['shell', 'navigation'], props);
  const { t } = useI18n();

  return (
    <CardContainer
      data-testid="hp-plus-widget-forest-first-card-container"
      headerText={t('dashboard.hpPlus.forestFirst.header')}
      content={(
        <Container>
          <ContainerImg>
            <img src={Images.forestFirst} alt="Forest First info" />
          </ContainerImg>
          <ContainerText
            className="label-small"
            data-testid="hp-plus-widget-forest-first-container-text"
          >
            {t('dashboard.hpPlus.forestFirst.bodyCopy')}
            {' '}
            <HyperlinkText
              onClick={ev => {
                ev.preventDefault();
                navigation.push('/forestfirst');
              }}
              url="/forestfirst"
            >
              {t('dashboard.hpPlus.forestFirst.link')}
            </HyperlinkText>
          </ContainerText>
        </Container>
      )}
    />
  );
};

export default ForestFirst;
