import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { path, pathEq } from 'ramda';
import highchartsInterface from '@veneer/highcharts-interface';
import useTheme from '@veneer/theme/dist/use_theme';
import { Chart, ChartProvider } from '@veneer/core';
import primitives from '@veneer/primitives';
import { useI18n } from '@jarvis/react-portal-addons';
import useDeviceCacheApiCall from '../../../hooks/useDeviceCacheApiCall';
import CardContainer from '../../../shared/CardContainer';
import {
  ContainerChart,
  ContainerChartLegend,
} from '../../../shared/ContainerChart';
import {
  ContainerLegend,
  Legend,
  LegendColor,
  LegendSection,
  LegendText,

} from '../../../shared/Legend';
import Config from '../../../Config';
import { DASHBOARD_ENTITLEMENTS } from '../../../utils/printerStatus';

const MONITORED = 'securityMonitored';
const NEEDS_ATTENTION = 'needsAttention';
const UNMONITORED = 'notMonitored';
const NO_DATA_AVALIABLE = 'unknown';

const SmartSecurity = props => {
  const {
    shell,
    showLine,
    stack,
  } = props;

  const theme = useTheme();

  const {
    authProvider,
    store,
  } = shell;

  const [printers, setPrinters] = useState({});
  const { t } = useI18n();
  const smartSecuritySolutionDataId = Config.get('smartSecuritySolutionDataId');

  const deviceCacheApiCall = useDeviceCacheApiCall({
    authProvider,
    stack,
    store,
  });

  useEffect(() => {
    const deviceList = deviceCacheApiCall.data;
    if (deviceList) {
      const devicesWithSS = deviceList.reduce((acc, printer) => {
        const hasSolutionSmartSecurity = printer?.solutions?.some(
          solution => solution === DASHBOARD_ENTITLEMENTS.smartSecurity,
        );

        if (hasSolutionSmartSecurity) {
          return (
            pathEq(['solutionMetadata', smartSecuritySolutionDataId, 'active'], 'true', printer)
              ? [...acc, path(['solutionMetadata', smartSecuritySolutionDataId], printer)]
              : [...acc, { result: NO_DATA_AVALIABLE }]
          );
        }
        return acc;
      }, []);

      setPrinters({
        monitored: devicesWithSS.filter(printer => printer.result === MONITORED).length,
        needsAttention: devicesWithSS.filter(printer => printer.result === NEEDS_ATTENTION).length,
        unmonitored: devicesWithSS.filter(printer => printer.result === UNMONITORED).length,
        noDataAvaliable: devicesWithSS.filter(printer => printer.result === NO_DATA_AVALIABLE).length,
        totalPrinter: devicesWithSS.length,
        withPrinter: devicesWithSS.length > 0,
      });
    }
  }, [deviceCacheApiCall.data, smartSecuritySolutionDataId]);

  const content = useMemo(() => (
    <ContainerChartLegend>
      <ContainerChart data-testid="hp-plus-smart-security-chart">
        <ChartProvider chartInterface={highchartsInterface}>
          <Chart
            options={
            {
              chart: {
                height: '210px',
                type: 'donut',
              },
              title: {
                text: `<span style="font-size: 40px">${printers.totalPrinter}</span>`,
              },
              subtitle: {
                useHTML: true,
                text: `<div style="text-align: center; width: 99%">${t('dashboard.hpPlus.smartSecurity.totalPrinters')}</div>`,
                style: {
                  whiteSpace: 'unset',
                },
              },
              plotOptions: {
                pie: {
                  innerSize: '85%',
                  size: '100%',
                  colors: (printers.withPrinter)
                    ? [
                      theme?.color.positive.base.default,
                      theme?.color.warning.base.hover,
                      theme?.color.foreground.disabled,
                      primitives.color.warmGray7,
                    ]
                    : [theme?.color.background.disabled],
                },
              },
              tooltip: {
                enabled: printers.withPrinter,
              },
              series: [
                {
                  name: `${t('dashboard.printers.header')}`,
                  data: (printers.withPrinter) ? [
                    [`${t('dashboard.hpPlus.smartSecurity.status.secured')}`, printers.monitored],
                    [`${t('dashboard.hpPlus.smartSecurity.status.needsAttention')}`, printers.needsAttention],
                    [`${t('dashboard.hpPlus.smartSecurity.status.unmonitored')}`, printers.unmonitored],
                    [`${t('dashboard.hpPlus.smartSecurity.status.noDataAvailable')}`, printers.noDataAvaliable],
                  ] : [
                    ['unknown', 1],
                  ],
                  states: {
                    hover: {
                      enabled: printers.withPrinter,
                    },
                  },
                },
              ],
            }
          }
          />
        </ChartProvider>
      </ContainerChart>
      <LegendSection>
        <ContainerLegend>
          <Legend data-testid="hp-plus-smart-security-chart-secured-legend">
            <LegendColor
              color={theme.color.positive.base.default}
              data-testid="hp-plus-smart-security-chart-secured-legend-color"
            />
            <LegendText className="label-small" data-testid="hp-plus-smart-security-chart-secured-legend-text">
              {t('dashboard.hpPlus.smartSecurity.status.secured')}
            </LegendText>
          </Legend>
          <Legend data-testid="hp-plus-smart-security-chart-needs-attention-legend">
            <LegendColor
              color={theme.color.warning.base.hover}
              data-testid="hp-plus-smart-security-chart-needs-attention-legend-color"
            />
            <LegendText className="label-small" data-testid="hp-plus-smart-security-chart-needs-attention-legend-text">
              {t('dashboard.hpPlus.smartSecurity.status.needsAttention')}
            </LegendText>
          </Legend>
        </ContainerLegend>
        <ContainerLegend>
          <Legend data-testid="hp-plus-smart-security-chart-unmonitored-legend">
            <LegendColor
              color={theme.color.foreground.disabled}
              data-testid="hp-plus-smart-security-chart-unmonitored-legend-color"
            />
            <LegendText className="label-small" data-testid="hp-plus-smart-security-chart-unmonitored-legend-text">
              {t('dashboard.hpPlus.smartSecurity.status.unmonitored')}
            </LegendText>
          </Legend>
          <Legend data-testid="hp-plus-smart-security-chart-no-data-available-legend">
            <LegendColor
              color={primitives.color.warmGray7}
              data-testid="hp-plus-smart-security-chart-no-data-available-legend-color"
            />
            <LegendText
              className="label-small"
              data-testid="hp-plus-smart-security-chart-no-data-available-legend-text"
            >
              {t('dashboard.hpPlus.smartSecurity.status.noDataAvailable')}
            </LegendText>
          </Legend>
        </ContainerLegend>
      </LegendSection>
    </ContainerChartLegend>
  ), [
    printers.monitored,
    printers.needsAttention,
    printers.noDataAvaliable,
    printers.totalPrinter,
    printers.unmonitored,
    printers.withPrinter,
    t,
    theme.color,
  ]);

  return (
    <CardContainer
      content={content}
      data-testid="hp-plus-widget-smart-security-card-container"
      headerText={t('dashboard.hpPlus.smartSecurity.header')}
      retryLoad={() => deviceCacheApiCall.makeApiCall()}
      showError={!!deviceCacheApiCall.error}
      showLine={showLine}
      showLoading={deviceCacheApiCall.pending}
    />
  );
};

SmartSecurity.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  showLine: PropTypes.bool,
  stack: PropTypes.number,
};

SmartSecurity.defaultProps = {
  showLine: false,
  stack: 1,
};

export default SmartSecurity;
