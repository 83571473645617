import forestFirst from './forest-first.svg';
import lineHeader from './line-header.svg';
import smartPro from './smart-pro.svg';
import warningGray from './warning-gray.svg';

const Images = {
  forestFirst,
  lineHeader,
  smartPro,
  warningGray,
};

export default Images;
