import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { Search as VeneerSearch } from '@veneer/core';
import { GenericModal, mixins } from '@jarvis/react-portal-addons';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${primitives.layout.size2}px;

  ${mixins.sm(`
    gap: ${primitives.layout.size5}px;
  `)}
`;

export const Timestamp = styled.span`
  color: ${props => (props.red ? primitives.color.red6 : '')};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.orbitalFour};
  flex-grow: 1;

  .custom-select {
    max-width: 200px;
  }

  @media screen and (max-width: ${primitives.layout.breakpoint2}px) {
    flex-direction: column;
    align-items: flex-start;
    .custom-select {
      max-width: 100%;
    }
  }
`;

export const Search = styled(VeneerSearch)`
  max-width: 400px;
  width: 100%;

  & #search-model-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: ${primitives.layout.breakpoint2}px) {
    max-width: 100%;
  }
`;

export const StyledGenericModal = styled(GenericModal)`
  .vn-modal--content {
    ${mixins.xs(`
      margin: ${primitives.layout.size4}px;
      padding: 40px ${primitives.layout.size6}px ${primitives.layout.size6}px;
      max-width: calc(100% - ${primitives.layout.size6}px - ${primitives.layout.size6}px);
    `)}

    ${mixins.sm(`
      margin: ${primitives.layout.size4}px;
      padding: 40px ${primitives.layout.size7}px ${primitives.layout.size7}px;
      max-width: calc(100% - ${primitives.layout.size7}px - ${primitives.layout.size7}px);
    `)}

    ${mixins.md(`
      margin: ${primitives.layout.size7}px;
      padding: 40px ${primitives.layout.size8}px ${primitives.layout.size8}px;
      max-width: calc(100% - ${primitives.layout.size8}px - ${primitives.layout.size8}px);
    `)}

    ${mixins.lg(`
      margin: ${primitives.layout.size8}px;
      padding: 40px 36px 36px;
      max-width: calc(100% - 72px);
    `)}

    @media (min-width: 1200px) {
      padding: 40px;
      max-width: calc(100% - 80px);
    }

    & > div > div {
      gap: ${({ theme }) => theme.orbitalFive};
    }
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${primitives.layout.size4}px;
`;
