import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Table,
  useCallSuccess,
  useI18n,
  useRootContext,
  veneerI18n,
} from '@jarvis/react-portal-addons';
import { Select } from '@veneer/core';
import {
  DetailedUsageDataModalDisplayed,
  DetailedUsageDataSelectYearDropdown,
  publishEvent,
} from '../../utils/analytics';
import useDeviceCacheApiCall from '../../hooks/useDeviceCacheApiCall';
import useTenancyRegistryApiCall from '../../hooks/useTenancyRegistryApiCall';
import useCalls from './useCalls';
import { getUniqueUserKey } from '../../utils/ldClient';
import {
  STORAGE_COLUMN_DATA_USAGE,
  formatValueToLocale,
  getEndDate,
  getStartDate,
} from '../../utils/usageData';
import DeviceName from './DeviceName';
import NoImageAvailable from '../../assets/images/no-image-available.svg';
import {
  ContainerContent,
  Content,
  Search,
  SearchContainer,
  StyledGenericModal,
  Timestamp,
} from './styles';

const timestampFormatOptions = {
  dateStyle: 'long',
  timeStyle: 'short',
};

const compareContains = (value, param) => value?.toLowerCase().includes(param?.toLowerCase().trim());

function buildTableData(
  devices,
  dataUnavailableText,
  locale,
  searchNameModel,
  dataTelemetry,
  hasLastDataReceivedFax,
  hasLastDataReceivedPrint,
  hasLastDataReceivedPrintArea,
  hasLastDataReceivedScan,
) {
  const newTableData = devices.filter(
    device => !searchNameModel
    || compareContains(device.identity?.friendlyName, searchNameModel)
    || compareContains(device.identity?.makeAndModel?.name, searchNameModel),
  )
    .map(({ deviceId, identity, images }, i) => {
      const {
        printedCounters,
        printedAreaCounters,
        scanCounters,
        faxCounters,
      } = dataTelemetry;

      const {
        deviceUuid, friendlyName, makeAndModel, programLevel,
      } = identity || {};
      const { name: modelName, number: modelNumber } = makeAndModel || {};
      const image = images?.length > 0 ? images[images.length - 1] : null;

      const printTimestamp = printedCounters?.find(device => device.deviceId === deviceId)?.lastDataReceivedOn;
      const faxTimestamp = faxCounters?.find(device => device.deviceId === deviceId)?.lastDataReceivedOn;
      const scanTimestamp = scanCounters?.find(device => device.deviceId === deviceId)?.lastDataReceivedOn;
      const printAreaTimestamp = printedAreaCounters?.find(device => device.deviceId === deviceId)?.lastDataReceivedOn;

      const printResetTimestamp = printedCounters?.find(device => device.deviceId === deviceId)?.lastResetTime;
      const faxResetTimestamp = faxCounters?.find(device => device.deviceId === deviceId)?.lastResetTime;
      const scanResetTimestamp = scanCounters?.find(device => device.deviceId === deviceId)?.lastResetTime;
      const printAreaResetTimestamp = printedAreaCounters?.find(device => device.deviceId === deviceId)?.lastResetTime;

      const resetTimestamps = [
        ...(printResetTimestamp ? [new Date(printResetTimestamp)] : []),
        ...(faxResetTimestamp ? [new Date(faxResetTimestamp)] : []),
        ...(scanResetTimestamp ? [new Date(scanResetTimestamp)] : []),
        ...(printAreaResetTimestamp ? [new Date(printAreaResetTimestamp)] : []),
      ];

      const mostRecentResetTimestamp = resetTimestamps.reduce((dateA, dateB) => (dateA > dateB ? dateA : dateB), null);

      const timestamps = [
        ...(printTimestamp ? [new Date(printTimestamp)] : []),
        ...(faxTimestamp ? [new Date(faxTimestamp)] : []),
        ...(scanTimestamp ? [new Date(scanTimestamp)] : []),
        ...(printAreaTimestamp ? [new Date(printAreaTimestamp)] : []),
      ];

      const mostRecentTimestamp = timestamps.reduce((dateA, dateB) => (dateA > dateB ? dateA : dateB), null);

      let timestamp = dataUnavailableText;
      if (mostRecentTimestamp && mostRecentTimestamp >= mostRecentResetTimestamp) {
        timestamp = new Intl
          .DateTimeFormat(locale?.replace('_', '-'), timestampFormatOptions)
          .format(mostRecentTimestamp);
      }

      const printPages = printedCounters?.find(x => x.deviceId === deviceId)?.totalPagePrinted;
      const printArea = printedAreaCounters?.find(x => x.deviceId === deviceId)?.totalAreaPrinted;
      const scanPages = scanCounters?.find(x => x.deviceId === deviceId)?.totalImages;
      const faxReceived = faxCounters?.find(x => x.deviceId === deviceId)?.totalPageFax?.receivedPages;
      const faxSent = faxCounters?.find(x => x.deviceId === deviceId)?.totalPageFax?.sentPages;

      const printAreaFormatted = formatValueToLocale({
        isPrintArea: true,
        total: printArea,
        data: printArea,
        locale,
      });

      const analyticsOptions = {
        metadata: {
          associatedDeviceUuid: deviceUuid,
          associatedDeviceProductNumber: modelNumber,
        },
      };

      return {
        name: (
          <DeviceName
            cloudId={deviceId}
            data-testid={`name-${i}`}
            hpPlus={programLevel?.toLowerCase() === 'hp+'}
            image={<img alt="Printer" src={image?.url || NoImageAvailable} />}
            modelName={modelName}
            modelNumber={modelNumber}
            name={friendlyName}
            analyticsOptions={analyticsOptions}
          />
        ),
        ...(hasLastDataReceivedPrint
          && {
            printPages: <span data-testid={`print-pages-${i}`}>{printPages || '0'}</span>,
          }
        ),
        ...(hasLastDataReceivedPrintArea
          && {
            printSqm: <span data-testid={`print-area-${i}`}>{printAreaFormatted}</span>,
          }
        ),
        ...(hasLastDataReceivedScan
          && {
            scanPages: <span data-testid={`scan-pages-${i}`}>{scanPages || '0'}</span>,
          }
        ),
        ...(hasLastDataReceivedFax
          && {
            faxSent: <span data-testid={`fax-sent-${i}`}>{faxSent || '0'}</span>,
            faxReceived: <span data-testid={`fax-received-${i}`}>{faxReceived || '0'}</span>,
          }
        ),
        timestamp: (
          <Timestamp
            data-testid={`timestamp-${i}`}
          >
            {timestamp}
          </Timestamp>
        ),
      };
    });

  return newTableData;
}

const getListYears = (startYear, endYear) => {
  const listYears = [];

  for (let currentYear = startYear; currentYear <= endYear; currentYear += 1) {
    listYears.push({
      value: currentYear,
      label: currentYear.toString(), // Convert to string to avoid issues with Select component
    });
  }

  return listYears;
};

const getFilterYear = (selectedYear, currentDate) => {
  const startDate = getStartDate(selectedYear);
  const endDate = getEndDate(selectedYear, currentDate);

  return {
    startDate,
    endDate,
  };
};

const startOrder = [
  'name',
  'printPages',
  'printSqm',
  'scanPages',
  'faxSent',
  'faxReceived',
  'timestamp',
];

const DetailedUsageModal = ({
  currentDate,
  currentTelemetryData,
  currentYear,
  currentYearIsEqualSelectedYear,
  show,
  onClose,
  'data-testid': dataTestId,
  locale,
  isSquareFeet,
}) => {
  const { dashboardShowFiltersDetailedUsage, dashboardHideColumnReorder } = useFlags();

  const userId = getUniqueUserKey(locale);
  const storageColumn = `${STORAGE_COLUMN_DATA_USAGE}_${userId}`;
  const orderSaved = localStorage.getItem(storageColumn);

  const [tableData, setTableData] = useState([]);
  const [yearSelected, setYearSelected] = useState(currentYear);
  const [storageLoaded, setStorageLoaded] = useState(false);
  const [searchNameModel, setSearchNameModel] = useState('');
  const [orderColumn, setOrderColumn] = useState(startOrder);
  const [dataTelemetry, setDataTelemetry] = useState(null);
  const [hasRun, setHasRun] = useState(false);
  const [hasLastDataReceivedOnFax, setHasLastDataReceivedOnFax] = useState(false);
  const [hasLastDataReceivedOnPrint, setHasLastDataReceivedOnPrint] = useState(false);
  const [hasLastDataReceivedOnPrintArea, setHasLastDataReceivedOnPrintArea] = useState(false);
  const [hasLastDataReceivedOnScan, setHasLastDataReceivedOnScan] = useState(false);
  const [dateCreatedOrgTenancy, setDateCreatedOrgTenancy] = useState(null);

  const { t } = useI18n();
  const { stack, shell } = useRootContext();
  const { authProvider, orgSelector, store } = shell;

  const { getTableI18n } = veneerI18n;

  const i18nTable = useMemo(
    () => getTableI18n({
      cancelButton: t('dashboard.detailedUsageDataModal.columnModal.cancel'),
      columnOptions: t('dashboard.detailedUsageDataModal.columnModal.title'),
      resetToDefault: t('dashboard.detailedUsageDataModal.columnModal.reset'),
      saveButton: t('dashboard.detailedUsageDataModal.columnModal.save'),
      noItems: t('dashboard.detailedUsageDataModal.noItems'),
      error: {
        message: t('dashboard.error.bodycopy'),
        labelRetry: t('dashboard.error.retry'),
      },
      noDataAvailable: {
        title: t('dashboard.cardAnalytics.noDataAvailable.title'),
        subTitle: t('dashboard.cardAnalytics.noDataAvailable.subTitle'),
      },
      noDataReturned: {
        title: t('dashboard.detailedUsageDataModal.noResults'),
        subTitle: t('dashboard.detailedUsageDataModal.adjustSearch'),
      },
      loading: {
        subTitle: t('dashboard.detailedUsageDataModal.loading'),
      },
    }),
    [getTableI18n, t],
  );

  const tenancyRegistryCall = useTenancyRegistryApiCall({
    authProvider,
    stack,
    id: orgSelector?.getOrgTenantId(),
  });

  const onTenancyRegistrySuccess = useCallback(
    data => {
      const orgDate = new Date(data?.dateCreated * 1000);
      setDateCreatedOrgTenancy(orgDate);
    },
    [setDateCreatedOrgTenancy],
  );

  useCallSuccess({
    call: tenancyRegistryCall,
    onSuccess: onTenancyRegistrySuccess,
  });

  const params = useMemo(() => {
    const filterDate = getFilterYear(currentYear, currentDate);
    return {
      ...filterDate,
      isSquareFeet,
    };
  }, [currentDate, currentYear, isSquareFeet]);

  useEffect(() => {
    if (currentTelemetryData && currentYearIsEqualSelectedYear) {
      setDataTelemetry(currentTelemetryData);
    }
  }, [currentTelemetryData, currentYearIsEqualSelectedYear]);

  useEffect(() => {
    if (userId && !storageLoaded) {
      setStorageLoaded(true);
      const { orderColumnSaved } = JSON.parse(orderSaved || '{}');
      if (orderColumnSaved) {
        setOrderColumn(orderColumnSaved);
      }
    }
  }, [orderSaved, userId, storageLoaded]);

  const numMonths = 12;

  const {
    makeApiCall: fetchDataTelemetry,
    allRequestsFailed: allRequestsFailedTelemetry,
  } = useCalls({
    authProvider,
    isSquareFeet,
    stack,
    params,
    setHasRun,
    setDataTelemetry,
    init: !currentYearIsEqualSelectedYear,
    currentDate,
    numMonths,
  });

  const createdOrgDate = dateCreatedOrgTenancy?.getFullYear() || currentYear;
  const listYears = getListYears(createdOrgDate, currentYear);

  const columns = useMemo(() => [
    {
      id: 'name',
      label: t('dashboard.detailedUsageDataModal.columns.name'),
      required: true,
    },
    ...(hasLastDataReceivedOnPrint ? [{
      id: 'printPages',
      label: t('dashboard.detailedUsageDataModal.columns.printedPages'),
    }] : []),
    ...(hasLastDataReceivedOnPrintArea ? [{
      id: 'printSqm',
      label: isSquareFeet
        ? t('dashboard.detailedUsageDataModal.columns.printAreaSquareFeet')
        : t('dashboard.detailedUsageDataModal.columns.printAreaSquareMeter'),
    }] : []),
    ...(hasLastDataReceivedOnScan ? [{
      id: 'scanPages',
      label: t('dashboard.detailedUsageDataModal.columns.pagesScanned'),
    }] : []),
    ...(hasLastDataReceivedOnFax ? [
      {
        id: 'faxSent',
        label: t('dashboard.detailedUsageDataModal.columns.faxPagesSent'),
      },
      {
        id: 'faxReceived',
        label: t('dashboard.detailedUsageDataModal.columns.faxPagesReceived'),
      },
    ] : []),
    {
      id: 'timestamp',
      label: t('dashboard.detailedUsageDataModal.columns.timestamp'),
    },
  ], [
    hasLastDataReceivedOnFax,
    hasLastDataReceivedOnPrint,
    hasLastDataReceivedOnPrintArea,
    hasLastDataReceivedOnScan,
    isSquareFeet,
    t,
  ]);

  const {
    pending,
    data: devices,
    makeApiCall: fetchDevices,
    error,
  } = useDeviceCacheApiCall({
    authProvider,
    stack,
    store,
    init: false,
  });

  useEffect(() => {
    if (show) {
      publishEvent(DetailedUsageDataModalDisplayed);
    }
  }, [show]);

  useEffect(() => {
    if (show && !devices) {
      fetchDevices();
    }
  }, [show, fetchDevices, devices]);

  const dataUnavailableText = useMemo(() => t('dashboard.detailedUsageDataModal.noDataAvailable'), [t]);

  useEffect(() => {
    if (pending) {
      return;
    }

    if (!error && devices && hasRun) {
      const {
        printedCounters,
        printedAreaCounters,
        scanCounters,
        faxCounters,
      } = dataTelemetry;

      const hasLastDataReceivedFax = faxCounters?.some(device => device?.lastDataReceivedOn);
      const hasLastDataReceivedPrint = printedCounters?.some(device => device?.lastDataReceivedOn);
      const hasLastDataReceivedPrintArea = printedAreaCounters?.some(device => device?.lastDataReceivedOn);
      const hasLastDataReceivedScan = scanCounters?.some(device => device?.lastDataReceivedOn);
      setHasLastDataReceivedOnFax(hasLastDataReceivedFax);
      setHasLastDataReceivedOnPrint(hasLastDataReceivedPrint);
      setHasLastDataReceivedOnPrintArea(hasLastDataReceivedPrintArea);
      setHasLastDataReceivedOnScan(hasLastDataReceivedScan);

      const newTableData = buildTableData(
        devices,
        dataUnavailableText,
        locale,
        searchNameModel,
        dataTelemetry,
        hasLastDataReceivedFax,
        hasLastDataReceivedPrint,
        hasLastDataReceivedPrintArea,
        hasLastDataReceivedScan,
      );
      setTableData(newTableData);
      return;
    }

    if (error) {
      setTableData([]);
    }
  }, [dataTelemetry, dataUnavailableText, devices, error, hasRun, locale, pending, searchNameModel]);

  const onSelectYear = useCallback(
    value => {
      if (value !== yearSelected) {
        publishEvent(DetailedUsageDataSelectYearDropdown);
        setYearSelected(value);
        const args = getFilterYear(value, currentDate);
        fetchDataTelemetry(args);
      }
    },
    [currentDate, fetchDataTelemetry, yearSelected],
  );

  const actionArea = useMemo(
    () => (
      <SearchContainer data-testId="search-form">
        <Select
          className="custom-select"
          data-testId="select-year"
          label={t('dashboard.detailedUsageDataModal.search.selectYearLabel')}
          options={listYears}
          value={[yearSelected]}
          onChange={({ value }) => onSelectYear(value)}
          clearIcon={false}
        />
        <Search
          data-testId="search-model-name"
          id="search-model-name"
          onChange={value => setSearchNameModel(value)}
          placeholder={t('dashboard.detailedUsageDataModal.search.nameAndModelPlaceholder')}
          value={searchNameModel}
        />
      </SearchContainer>
    ),
    [listYears, onSelectYear, searchNameModel, t, yearSelected],
  );

  const getPreferences = useCallback(() => {
    const localPreferences = {
      width: [
        {
          columnId: 'name',
          width: 320,
        },
        {
          columnId: 'printPages',
          width: 200,
        },
        {
          columnId: 'printSqm',
          width: 200,
        },
        {
          columnId: 'scanPages',
          width: 200,
        },
        {
          columnId: 'faxSent',
          width: 200,
        },
        {
          columnId: 'faxReceived',
          width: 200,
        },
        {
          columnId: 'timestamp',
          width: 260,
        },
      ],
      defaultOrder: startOrder,
      order: orderColumn,
    };

    return localPreferences;
  }, [orderColumn]);

  useEffect(() => {
    if (storageLoaded) {
      const { defaultOrder } = getPreferences();
      const content = {
        defaultOrder,
        orderColumnSaved: orderColumn,
      };
      localStorage.setItem(storageColumn, JSON.stringify(content));
    }
  }, [getPreferences, orderColumn, storageColumn, storageLoaded]);

  const onRetry = useCallback(() => {
    fetchDevices();
    const args = getFilterYear(yearSelected, currentDate);
    fetchDataTelemetry(args);
  }, [currentDate, fetchDataTelemetry, fetchDevices, yearSelected]);

  const content = useMemo(
    () => (
      <ContainerContent>
        {(tableData?.length === 0
          || (dashboardHideColumnReorder
            && dashboardShowFiltersDetailedUsage)) && <>{actionArea}</>}

        <Table
          tableData={{
            ...(!dashboardHideColumnReorder
              && dashboardShowFiltersDetailedUsage && { actionArea }),
            ...(!dashboardHideColumnReorder && {
              columnReorder: true,
              onColumnReorder: value => setOrderColumn(value),
            }),
            columns,
            data: tableData,
            loadingDataLength: 5,
            preferences: getPreferences(),
            i18n: i18nTable,
            'data-testid': 'modal-table',
          }}
          loading={pending || !hasRun}
          error={(error || allRequestsFailedTelemetry) && !pending && { onRetry }}
          noDataReturned={tableData?.length === 0}
          i18n={i18nTable}
        />
      </ContainerContent>
    ),
    [
      actionArea,
      allRequestsFailedTelemetry,
      columns,
      dashboardHideColumnReorder,
      dashboardShowFiltersDetailedUsage,
      error,
      getPreferences,
      hasRun,
      i18nTable,
      onRetry,
      pending,
      tableData,
    ],
  );

  return (
    <StyledGenericModal
      show={show}
      header={{
        title: t('dashboard.detailedUsageDataModal.titleNew'),
        dataTestId: 'modal-title',
      }}
      dataTestId={dataTestId}
      onClose={onClose}
      responsiveness="full"
      closeButton
    >
      <Content>
        <p className="body" data-testid="modal-description">
          {t('dashboard.detailedUsageDataModal.descriptionNew')}
        </p>
        {content}
      </Content>
    </StyledGenericModal>
  );
};

DetailedUsageModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
  locale: PropTypes.string.isRequired,
  currentYear: PropTypes.number.isRequired,
  currentDate: PropTypes.objectOf(PropTypes.any).isRequired,
  isSquareFeet: PropTypes.bool,
  currentYearIsEqualSelectedYear: PropTypes.bool,
  currentTelemetryData: PropTypes.objectOf(PropTypes.any),
};

DetailedUsageModal.defaultProps = {
  currentTelemetryData: null,
  show: false,
  'data-testid': null,
  isSquareFeet: true,
  currentYearIsEqualSelectedYear: false,
};

export default DetailedUsageModal;
