import { useMemo } from 'react';
import useDeviceCacheApiCall from './useDeviceCacheApiCall';

const useHpPlusDevicesCount = ({
  authProvider,
  stack,
  store,
}) => {
  const {
    data,
    error,
    pending,
    success,
  } = useDeviceCacheApiCall({
    authProvider,
    stack,
    store,
  });

  const numberOfDevices = useMemo(() => (data
    ? data.reduce((acc, cur) => (cur?.identity?.programLevel === 'HP+' ? acc + 1 : acc), 0)
    : 0),
  [data]);

  return {
    error,
    pending,
    success,
    data: {
      numberOfDevices,
    },
  };
};

export default useHpPlusDevicesCount;
