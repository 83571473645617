import { useCallback } from 'react';
import { AccountMgtSvcClient, AccountMgtSvcClientV3 } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useAccountProgramInfosCall({
  authProvider,
  stack,
  init,
  store,
  initialData,
  version = 'v2',
}) {
  const saveToStore = useCallback(data => {
    store.setState({
      dashboard: {
        programInfos: {
          ...data,
        },
      },
    }, { deepSafeSetValue: true });
  }, [store]);

  const apiCall = useCallback(async ({ accountId, userId }) => {
    const accountMgtSvcClient = version === 'v2'
      ? new AccountMgtSvcClient(stack, authProvider)
      : new AccountMgtSvcClientV3(stack, authProvider);
    const response = await accountMgtSvcClient.getProgramInfos(accountId, userId);

    saveToStore(response.data);

    return response.data;
  }, [authProvider, saveToStore, stack, version]);

  return useApiCall({
    apiCall,
    init,
    initialData,
  });
}
