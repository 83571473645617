import SMBMainTheme from './styles';

export const xs = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.xs} {
    ${rules}
  }
  `
);

export const mobile = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.mobile} {
    ${rules}
  }
  `
);

export const tablet = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.tablet} {
    ${rules}
  }
  `
);

export const desktop = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.desktop} {
    ${rules}
  }
  `
);

export const desktopAndTablet = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.desktop}, ${SMBMainTheme.mediaQueries.tablet} {
    ${rules}
  }
  `
);

export const tabletAndMobile = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.tablet}, ${SMBMainTheme.mediaQueries.mobile} {
    ${rules}
  }
  `
);

export const large = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.large}) {
    ${rules}
  }
  `
);

export const xLarge = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.xLarge} {
    ${rules}
  }
  `
);

export const largeAndxLarge = rules => (
  `
  @media ${SMBMainTheme.mediaQueries.large}, ${SMBMainTheme.mediaQueries.xLarge} {
    ${rules}
  }
  `
);
