import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  GRANT_CHECK_TYPE, GRANT_STATES, LoaderWidget, useGrants,
} from '@jarvis/react-portal-addons';
import Header from './Header';
import {
  CardContainer,
  Container,
  WidgetContainer,
} from './styles';
import SmartSecurity from './SmartSecurity';
import PrintAnyWhere from './PrintAnyWhere';
import ForestFirst from './ForestFirst';
import useAccountEntitlementsApiCall from '../../hooks/useAccountEntitlementsApiCall';
import { DASHBOARD_ENTITLEMENTS } from '../../utils/printerStatus';

const grantsToCheck = [
  { grant: DASHBOARD_ENTITLEMENTS.smartSecurity },
  { grant: DASHBOARD_ENTITLEMENTS.printAnywhere },
  { grant: DASHBOARD_ENTITLEMENTS.forestFirst },
];

const states = [GRANT_STATES.ENABLED];

const HPPlusWidget = props => {
  const {
    shell,
    stack,
  } = props;

  const {
    authProvider,
    store,
    grants,
  } = shell;

  const { grantsSupport } = useFlags();

  const [numberOfColumns, setNumberOfColumns] = useState(0);
  const accountEntitlements = useAccountEntitlementsApiCall({
    stack, authProvider, store, version: grantsSupport ? 'v3' : 'v2',
  });

  const { grantsCall } = useGrants({
    grants: grantsToCheck,
    init: grantsSupport,
    checkType: GRANT_CHECK_TYPE.INDIVIDUAL,
    states,
    grantsInterface: grants,
  });

  const {
    [DASHBOARD_ENTITLEMENTS.smartSecurity]: hasSmartSecurityGrant,
    [DASHBOARD_ENTITLEMENTS.printAnywhere]: hasPrintAnywhereGrant,
    [DASHBOARD_ENTITLEMENTS.forestFirst]: hasForestFirstGrant,
  } = grantsCall.data ?? {};

  const hasSmartSecurity = grantsSupport
    ? hasSmartSecurityGrant
    : accountEntitlements?.data?.smartSecurityEnable;

  const hasPrintAnywhere = grantsSupport
    ? hasPrintAnywhereGrant
    : accountEntitlements?.data?.printAnywhereEnable;

  const hasForestFirst = grantsSupport
    ? hasForestFirstGrant
    : accountEntitlements?.data?.forestFirstEnable;

  const isPending = accountEntitlements.loading || grantsCall.pending;

  useEffect(() => {
    const columnCount = [hasSmartSecurity, hasPrintAnywhere, hasForestFirst]
      .filter(Boolean).length;

    setNumberOfColumns(columnCount);
  }, [hasSmartSecurity, hasPrintAnywhere, hasForestFirst, numberOfColumns]);

  const content = useMemo(() => (
    <div>
      <Header {...props} />
      <WidgetContainer numberOfColumns={numberOfColumns}>
        {isPending && <LoaderWidget />}
        {hasSmartSecurity && (
          <SmartSecurity
            {...props}
            showLine={hasSmartSecurity}
          />
        )}
        {hasPrintAnywhere && (
          <PrintAnyWhere
            {...props}
            showLine={hasForestFirst}
          />
        )}
        {hasForestFirst && (
          <ForestFirst {...props} />
        )}
      </WidgetContainer>
    </div>
  ), [
    numberOfColumns,
    hasSmartSecurity,
    hasPrintAnywhere,
    hasForestFirst,
    isPending,
    props,
  ]);

  const showHpPlusCard = hasSmartSecurity
    || hasPrintAnywhere
    || hasForestFirst;

  return (
    <>
      {showHpPlusCard && (
        <Container data-testid="hp-plus-widget">
          <CardContainer appearance="dropShadow" content={content} />
        </Container>
      )}
    </>
  );
};

HPPlusWidget.defaultProps = {
  stack: 1,
};

HPPlusWidget.propTypes = {
  stack: PropTypes.number,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HPPlusWidget;
