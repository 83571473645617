import { useCallback } from 'react';
import { useApiCall } from '@jarvis/react-portal-addons';
import getJobsFaxCounters from '../services/getJobsFaxCounters';
import getJobsPrintCounters from '../services/getJobsPrintCounters';
import getJobsScanCounters from '../services/getJobsScanCounters';
import getJobsPrintAreaCounters from '../services/getJobsPrintAreaCounters';

function useJobTelemetryApiCall({
  authProvider,
  init = true,
  stack,
  params,
}) {
  const fetchFaxCounter = useCallback(
    ({ startDate, endDate }) => getJobsFaxCounters(
      {
        stack, authProvider, startDate, endDate,
      },
    ),
    [authProvider, stack],
  );
  const faxCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchFaxCounter,
    params,
    init,
  });

  const fetchPrintCounter = useCallback(
    ({ startDate, endDate }) => getJobsPrintCounters({
      stack, authProvider, startDate, endDate,
    }),
    [authProvider, stack],
  );
  const printCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchPrintCounter,
    init,
    params,
  });

  const fetchScanCounter = useCallback(
    ({ startDate, endDate }) => getJobsScanCounters(
      {
        stack, authProvider, startDate, endDate,
      },
    ),
    [authProvider, stack],
  );
  const scanCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchScanCounter,
    init,
    params,
  });

  const fetchPrintAreaCounter = useCallback(
    ({ startDate, endDate }) => getJobsPrintAreaCounters({
      stack, authProvider, startDate, endDate,
    }),
    [authProvider, stack],
  );

  const printAreaCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchPrintAreaCounter,
    init,
    params,
  });

  const data = {
    print: printCounterCall,
    printArea: printAreaCounterCall,
    scan: scanCounterCall,
    fax: faxCounterCall,
  };

  const pending = printCounterCall.pending
               || printAreaCounterCall.pending
               || scanCounterCall.pending
               || faxCounterCall.pending;

  const success = printCounterCall.success
               || printAreaCounterCall.success
               || scanCounterCall.success
               || faxCounterCall.success;

  const error = printCounterCall.error
             || printAreaCounterCall.error
             || scanCounterCall.error
             || faxCounterCall.error;

  const allRequestsFailed = printCounterCall.error
                          && printAreaCounterCall.error
                          && scanCounterCall.error
                          && faxCounterCall.error;

  const makeApiCall = useCallback(args => {
    printCounterCall.makeApiCall(args);
    printAreaCounterCall.makeApiCall(args);
    scanCounterCall.makeApiCall(args);
    faxCounterCall.makeApiCall(args);
  }, [
    printCounterCall,
    printAreaCounterCall,
    scanCounterCall,
    faxCounterCall,
  ]);

  return {
    data,
    pending,
    error,
    success,
    makeApiCall,
    allRequestsFailed,
  };
}

export default useJobTelemetryApiCall;
