import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import { LogoHp, LogoHpPlus } from '@veneer/core';

export const Container = styled.div`
  position: relative;
`;

export const Grid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: ${({ hasSmartPro, isHpPlus }) => {
    const rows = 5 + [hasSmartPro, isHpPlus].filter(Boolean).length;
    return `repeat(${rows}, min-content);`;
  }};
  position: relative;
  padding: 16px;
  // 1920 is the max content width (side menu + content area), side menu is 260px.
  max-width: 1660px;
  z-index: 1;

  ${mixins.md(`
    gap: 20px;
    padding: 28px;
  `)}

  ${({ hasSmartPro, isHpPlus }) => `
    ${mixins.lg(`
      gap: 24px;
      grid-template-rows: repeat(${3 + [hasSmartPro, isHpPlus].filter(Boolean).length}, min-content);
      padding: 32px;
    `)}
  `};
`;

export const Header = styled.div`
  align-items: center;
  column-gap: 24px;
  display: flex;
  flex-direction: row;
  grid-column: 1 / span 12;
  grid-row: 1 / span 1;
  height: 64px;
  row-gap: 0;
  width: 100%;
`;

export const HPPlus = styled(LogoHpPlus)`
  height: 64px;
  width: 64px;
`;

export const HP = styled(LogoHp)`
  height: 48px;
  width: 48px;
`;

export const NotificationContainer = styled.div`
  grid-column: 1 / span 12;
  grid-row: 2 / span 1;
  height: 420px;

  ${mixins.lg(`
    grid-column: 1 / span 8;
  `)}
`;

export const StatusContainer = styled.div`
  grid-column: 1 / span 12;
  grid-row: 3 / span 1;
  height: 420px;

  ${mixins.lg(`
    grid-column: 9 / span 4;
    grid-row: 2 / span 1;
  `)}
`;

export const PrintersWidgetContainer = styled.div`
  grid-column: 1 / span 12;
  grid-row: 4 / span 1;

  ${mixins.lg(`
    grid-column: 1 / span 5;
    grid-row: 3 / span 1;
  `)}
`;

export const UsageContainer = styled.div`
  height: 504px;
  grid-column: 1 / span 12;
  grid-row: 5 / span 1;

  ${mixins.lg(`
    grid-column: 6 / span 7;
    grid-row: 3 / span 1;
  `)}

  ${mixins.xs(`
    height: 646px;
  `)}
`;

export const HpPlusContainer = styled.div`
  grid-column: 1 / span 12;
  grid-row: 6 / span 1;

  ${mixins.lg(`
    grid-row: 4 / span 1;
  `)}
`;

export const HpSmartProContainer = styled.div`
  grid-column: 1 / span 12;
  grid-row: ${({ isHpPlus }) => (isHpPlus ? 7 : 6)} / span 1;

  ${mixins.lg(`
    grid-column: 1 / span 6;
    grid-row: ${({ isHpPlus }) => (isHpPlus ? 5 : 4)} / span 1;
  `)}

  ${({ isHpPlus }) => `
    ${mixins.lg(`
      grid-column: 1 / span 6;
      grid-row: ${isHpPlus ? 5 : 4} / span 1;
    `)}
  `};
`;
