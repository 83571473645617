import { TelemetryClient } from '@jarvis/web-stratus-client';

export default async function getJobsScanCounters({
  stack,
  authProvider,
  startDate,
  endDate,
  numDays,
}) {
  const client = new TelemetryClient(stack, authProvider);
  const response = await client.getScanCountersByTenancy(undefined, undefined, startDate, endDate, numDays);

  return response;
}
