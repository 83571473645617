import styled from 'styled-components';
import SMBMainTheme from '../../styles/styles';

export const LegendSection = styled.div`
  @media ${SMBMainTheme.mediaQueries.large}, ${SMBMainTheme.mediaQueries.mobile}  {
    margin: 22px 0 0;
    > div:nth-of-type(2) {
      margin-top: 16px;
      > div:nth-of-type(2) {
        margin-left: 20px;
      }
    }
  }
`;

export const LegendColor = styled.div`
  height: 16px;
  width: 16px;
  background: ${props => props.color};
  border-radius: 4px;
  margin-top: 1px;
`;

export const LegendText = styled.div`
  margin: 0 0 0 8px;
  color:  ${({ theme }) => theme.color.foreground.default};
`;

export const ContainerLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${SMBMainTheme.breakpoints.xtMin}), (min-width: ${SMBMainTheme.breakpoints.smMin}px) and (max-width: ${SMBMainTheme.breakpoints.mdMax}px){
    display: block;
    align-items: flex-start;
    justify-content: left;
  }
`;

export const ContainerPrintLegend = styled(ContainerLegend)`
  @media (min-width: ${SMBMainTheme.breakpoints.smMin}px){
    display: block;
    align-items: flex-start;
    justify-content: left;
  }
`;

export const Legend = styled.div`
  display: flex;
  margin: 0 0 0 24px;
  @media ${SMBMainTheme.mediaQueries.xLarge},  (min-width: ${SMBMainTheme.breakpoints.smMin}px) and (max-width: ${SMBMainTheme.breakpoints.mdMax}px){
    display: flex;
    margin: 0 0 12px;
  }
`;

export const PrintLegend = styled(Legend)`
  align-items: center;
  @media (min-width: ${SMBMainTheme.breakpoints.smMin}px){
    display: flex;
    margin: 0 0 12px;
  }
`;
