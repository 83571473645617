import PropTypes from 'prop-types';
import { path } from 'ramda';

const activity = 'Home-v01';
const eventDetailVersion = '1.1.0';
const eventDetailOneDotThreeVersion = '1.3.0';

export const publishEvent = event => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window);

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined');
    return;
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0',
    },
  ]);
};

export const AnalyticsEventPropType = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  CONTROL_TOGGLE_DISABLED: 'ControlToggleDisabled',
  CONTROL_TOGGLE_ENABLED: 'ControlToggleEnabled',
  MODAL_WINDOW_DISPLAYED: 'ModalWindowDisplayed',
  MODULE_DISPLAYED: 'ModuleDisplayed',
  MODULE_INITIALIZED: 'ModuleInitialized',
  SCREEN_DISPLAYED: 'ScreenDisplayed',
};

// Events

export const HomeScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  version: eventDetailVersion,
};

export const PrintersItemClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/ReactSmbPrinters/',
  screenName: 'Printers',
  controlName: 'PrintersNameButton',
  version: eventDetailVersion,
};

export const UsageToggleClickedColor = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'UsageColorSidesButton',
  controlDetail: 'Color',
  version: eventDetailVersion,
};

export const UsageToggleClickedSides = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'UsageColorSidesButton',
  controlDetail: 'Sides',
  version: eventDetailVersion,
};

export const UsageDataNextYearButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'NextYearButton',
  version: eventDetailOneDotThreeVersion,
};

export const UsageDataPreviousYearButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'PreviousYearButton',
  version: eventDetailOneDotThreeVersion,
};

export const UsageDataPrintUsageButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'PrintUsageButton',
  version: eventDetailOneDotThreeVersion,
};

export const UsageDataPrintAreaUsageButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'PrintAreaUsageButton',
  version: eventDetailOneDotThreeVersion,
};

export const UsageDataScanUsageButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'ScanUsageButton',
  version: eventDetailOneDotThreeVersion,
};

export const UsageDataFaxUsageButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'FaxUsageButton',
  version: eventDetailOneDotThreeVersion,
};

export const UsageDataViewUsageDetailsButton = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'ViewUsageDetailsButton',
  version: eventDetailOneDotThreeVersion,
};

export const DetailedUsageDataModalDisplayed = {
  action: ACTIONS.MODAL_WINDOW_DISPLAYED,
  activity,
  screenPath: '/Home/',
  screenName: 'DetailedUsageData',
  version: eventDetailOneDotThreeVersion,
};

export const DetailedUsageDataSelectYearDropdown = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/Home/',
  screenName: 'DetailedUsageData',
  controlName: 'SelectYearDropdown',
  version: eventDetailOneDotThreeVersion,
};

export const DetailedUsageDataPrinterNameLink = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: '/Home/',
  screenName: 'DetailedUsageData',
  controlName: 'PrinterNameLink',
  version: eventDetailOneDotThreeVersion,

};

export const HpSmartProClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: 'HpSmartProActiveButton',
  version: eventDetailVersion,
};

// Events helpers

export const getModuleInitializedEvent = component => ({
  action: ACTIONS.MODULE_INITIALIZED,
  activity,
  screenPath: '/',
  screenName: 'Home',
  controlName: component,
  version: eventDetailVersion,
});

export const getModuleDisplayedEvent = (screenPath, screenName) => ({
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion,
});
