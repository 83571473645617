import styled from 'styled-components';
import { SegmentedControl } from '@veneer/core';
import { mixins } from '@jarvis/react-portal-addons';

export const Toggle = styled(SegmentedControl)`
  ${mixins.xs(`
    width: 100%;
  `)}

  > * {
    ${mixins.xs(`
      justify-content: normal;
    `)}
  }
`;

export default Toggle;
