import styled from 'styled-components';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 36px 36px 36px;
`;

export const Header = styled.div`
  padding: 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.stroke.default};
`;

export const Image = styled.div`
  margin: 24px 0 20px;
`;
