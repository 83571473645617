import React from 'react';
import PropTypes from 'prop-types';
import { BlackHPPlus, useI18n } from '@jarvis/react-portal-addons';
import useHpPlusDeviceCounter from '../../../hooks/useHpPlusDevicesCount';
import {
  Container,
  Space,
  Text1,
  Text2,
} from './style';

const Header = props => {
  const {
    shell,
    stack,
  } = props;

  const {
    authProvider,
    store,
  } = shell;

  const { t } = useI18n();
  const deviceHPPlus = useHpPlusDeviceCounter({ authProvider, stack, store });

  return (
    <Container className="subtitle-regular">
      <Space>
        <BlackHPPlus
          alt="HP Plus"
          id="black"
        />
      </Space>
      <Text1 data-testid="hp-plus-widget-header">
        {t('dashboard.hpPlus.header')}
      </Text1>
      {!deviceHPPlus.loading && !deviceHPPlus.error && (
        <Text2 data-testid="hp-plus-widget-counter">
          {t('dashboard.hpPlus.subHeader', { numberPrinters: deviceHPPlus.data.numberOfDevices })}
        </Text2>
      )}
    </Container>
  );
};

Header.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  stack: PropTypes.number,
};

Header.defaultProps = {
  stack: 1,
};

export default Header;
