import React from 'react';
import Dashboard from '../src/index';
import pkg from '../package.json';
import { namespace } from './configs/projectNames';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  //  Root(props) {
  // You can find all Shell´s props here. See "./types/shell" for more details.
  // const { v1 } = window.Shell as ShellProps

  // You can find the "stack" information by props
  // const stack = props.stack.

  const shell = window.Shell.v1;

  return (
    <section
      id={pkg.name}
      className={namespace}
      style={{
        height: '100%',
        minHeight: 'calc(100vh - 176px)',
      }}
    >
      <Dashboard {...props} shell={shell} />
    </section>
  );
}
