import { useCallback } from 'react';
import { useApiCall } from '@jarvis/react-portal-addons';
import getTenancyNode from '../services/getTenancyNode';

const useTenancyRegistryApiCall = ({
  authProvider,
  stack,
  id,
}) => {
  const apiCall = useCallback(async () => {
    const response = await getTenancyNode({ authProvider, stack, id });

    return response;
  }, [authProvider, id, stack]);

  return useApiCall({
    apiCall,
  });
};

export default useTenancyRegistryApiCall;
